

const QuestionIcon = () => {
    return (
        <svg className={"svg-icon"} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                stroke="#EEB665" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M12 18.375C12.8284 18.375 13.5 17.7034 13.5 16.875C13.5 16.0466 12.8284 15.375 12 15.375C11.1716 15.375 10.5 16.0466 10.5 16.875C10.5 17.7034 11.1716 18.375 12 18.375Z"
                fill="#EEB665"/>
            <path
                d="M12 13.5C12.5933 13.5 13.1734 13.3241 13.6667 12.9944C14.1601 12.6648 14.5446 12.1962 14.7716 11.6481C14.9987 11.0999 15.0581 10.4967 14.9424 9.91473C14.8266 9.33279 14.5409 8.79824 14.1213 8.37868C13.7018 7.95912 13.1672 7.6734 12.5853 7.55765C12.0033 7.44189 11.4001 7.5013 10.8519 7.72836C10.3038 7.95543 9.83524 8.33994 9.50559 8.83329C9.17595 9.32664 9 9.90666 9 10.5"
                stroke="#EEB665" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default QuestionIcon