import UserInterface from '../../interfaces/user';
import BalanceInterface from '../../interfaces/balance';
import Offer from "../../interfaces/offer";
import GameInterface from "../../interfaces/game";
import VerificationStatus from "../../interfaces/VerificationStatus";
import {saveVerificationStatus} from "../../utils/cookies";
import SmarticoMiniGame from "@/AbsoluteComponents/interfaces/smartico_minigames";
import ClaimableOfferInterface from "@/AbsoluteComponents/interfaces/claimable_offer_interface";

export const UPDATE_USER_DATA = '@account/UPDATE_USER_DATA';
export const USER_IS_UPDATING = '@account/USER_IS_UPDATING';
export const UPDATE_USER_BALANCE = '@ACCOUNT/UPDATE_USER_BALANCE';
export const UPDATE_NEW_WALLET = '@ACCOUNT/UPDATE_NEW_WALLET';
export const UPDATE_OFFER = '@ACCOUNT/UPDATE_OFFER';
export const UPDATE_OFFER_CONDITION = '@ACCOUNT/UPDATE_BALANCE_CONDITION';
export const UPDATE_FAVORITES_GAMES = '@ACCOUNT/UPDATE_FAVORITES_GAMES';
export const UPDATE_FREE_SPINS_GAMES = '@ACCOUNT/UPDATE_FREE_SPINS_GAMES';
export const UPDATE_FREE_BONUS_GAMES = '@ACCOUNT/UPDATE_FREE_BONUS_GAMES';
export const UPDATE_TOGGLE_DOCUMENTS_LIST = '@ACCOUNT/UPDATE_TOGGLE_DOCUMENTS_LIST';
export const UPDATE_VERIFICATION_STATUS = '@ACCOUNT/UPDATE_VERIFICATION_STATUS';
export const UPDATE_PLAYER_QUEST_STATUS = '@ACCOUNT/UPDATE_PLAYER_QUEST_STATUS';
export const UPDATE_LEADERBOARD = '@ACCOUNT/UPDATE_LEADERBOARD';
export const UPDATE_QUEST_STATUS = '@ACCOUNT/UPDATE_QUEST_STATUS';
export const UPDATE_SPIN_STATUS = '@ACCOUNT/UPDATE_SPIN_STATUS';
export const UPDATE_DEPOSIT_NUM = '@ACCOUNT/UPDATE_DEPOSIT_NUM';
export const UPDATE_BET_BALANCE = '@ACCOUNT/UPDATE_BET_BALANCE';
export const UPDATE_VALIDATIONS = '@ACCOUNT/UPDATE_VALIDATIONS';
export const UPDATE_USER_DOCS = '@ACCOUNT/UPDATE_USER_DOCS';
export const UPDATE_TIMED_FREE_SPINS = '@ACCOUNT/UPDATE_TIMED_FREE_SPINS';
export const UPDATE_SMARTICO_MINI_GAMES = '@ACCOUNT/UPDATE_SMARTICO_MINI_GAMES';
export const OPEN_BONUS_CENTER = '@ACCOUNT/OPEN_BONUS_CENTER';
export const RESET_PWD_PHONE_ENDING = '@ACCOUNT/RESET_PWD_PHONE_ENDING';
export const SMARTICO_USER_PROPS = '@ACCOUNT/SMARTICO_USER_PROPS';
export const SET_CLAIM_PROMOTIONS = '@ACCOUNT/SET_CLAIM_PROMOTIONS';



export function updateDepositNum(num:number) {
    return {type: UPDATE_DEPOSIT_NUM, payload: num}
}

export function updateUserDocs(docs:Array<any>) {
    return {type: UPDATE_USER_DOCS, payload: docs}
}

export function updateValidations(res:boolean) {
    return {type: UPDATE_VALIDATIONS, payload: res}
}

export function updateLeaderboard(leaderboard:any) {
    return {type: UPDATE_LEADERBOARD, payload: leaderboard}
}

export function updateBetBalance(num:number) {
    return {type: UPDATE_BET_BALANCE, payload: num}
}

export function updateUserData(user?:UserInterface) {
    return {type: UPDATE_USER_DATA, payload: user}
}

export function updateSpinStatus(spinStatus:any) {
    return {type: UPDATE_SPIN_STATUS, payload: spinStatus}
}

export function userIsUpdating(updating:boolean) {
    return {type: USER_IS_UPDATING, payload: updating}
}

export function updateUserBalance(user?:BalanceInterface) {
    return{ type: UPDATE_USER_BALANCE, payload: user}
}

export function updateUserNewWallet(obj:any) {
    return{ type: UPDATE_NEW_WALLET, payload: obj}
}

export function updateOffer(offers:Array<Offer>) {
    return{type: UPDATE_OFFER, payload: offers}
}

export function updateOfferConditions(offersCondition:string) {
    return { type: UPDATE_OFFER_CONDITION, payload: offersCondition}
}

export function updateFavoritesGames(games:Array<GameInterface>) {
    return { type: UPDATE_FAVORITES_GAMES, payload: games}
}

export function updateFreeSpinsGames(ids:Array<string>) {
    return { type: UPDATE_FREE_SPINS_GAMES, payload: ids}
}

export function updateTimedFreeSpins(ids:Array<string>) {
    return { type: UPDATE_TIMED_FREE_SPINS, payload: ids}
}

export function updateFreeBonusGames(ids:Array<string>) {
    return { type: UPDATE_FREE_BONUS_GAMES, payload: ids}
}

export function updatePlayerQuestStatus(status: any) {
    return { type: UPDATE_PLAYER_QUEST_STATUS, payload: status}
}

export function updateQuestStatus(status: any) {
    return { type: UPDATE_QUEST_STATUS, payload: status}
}

export function updateToggleDocumentsList() {
    return { type: UPDATE_TOGGLE_DOCUMENTS_LIST}
}

export function updateVerificationStatus(list:Array<VerificationStatus>) {
    saveVerificationStatus(list);
    return { type: UPDATE_VERIFICATION_STATUS, payload: list}
}

export function updateSmarticoMiniGames(list:Array<SmarticoMiniGame>) {
    return { type: UPDATE_SMARTICO_MINI_GAMES, payload: list}
}

export function openBonusCenter(x:boolean) {
    return { type: OPEN_BONUS_CENTER, payload: x}
}

export function setResetPwdPhoneEnding(x:string) {
    return { type: RESET_PWD_PHONE_ENDING, payload: x}
}

export function setSmrticoUserProps(x:any) {
    return { type: SMARTICO_USER_PROPS, payload: x}
}

export function setClaimPromotions(x:Array<ClaimableOfferInterface>) {
    return { type: SET_CLAIM_PROMOTIONS, payload: x}
}