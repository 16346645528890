import React, {useEffect, useRef, useState} from "react";
// @ts-ignore
import numberFormatter from 'number-formatter';
// @ts-ignore
import styles from "@/template/styles/JpFever.module.scss"
import clsx from "clsx";
import {useSelector} from "react-redux";



interface Props {
    id: string;
    jackpot: any
    className: string
    textWidth: number
    textHeight: number
    couldBeDistort?: boolean
    value:any
    isolated?:boolean
    style?:any
    level?:any
    on_jp_page?:boolean
    static_counter?:boolean
}

const JackpotCounterDynamic: React.FC<Props> = ({ id, value, className, textWidth, style, jackpot, level, on_jp_page, static_counter }) => {
    
    const interval = useRef<any>(null);
    const elem = useRef<any>(null);
    const playJackpot = useSelector((state: any) => state.common.playJackpot)
    const [valueState, setValueState] = useState(value)
    const [offsetState, setOffsetState] = useState(0)
    const level_id = level.id
    
    const getConfig = () => {
        let end_offset = static_counter ? 0 : 0.3
        let start_offset = 0
        let increment = 0.01
        
        if(offsetState === 0) {
            if(valueState > 100) {
                start_offset = 2
            } else if(valueState > 60 && valueState <= 100) {
                start_offset = 1
            } else if (valueState > 10 && valueState <= 60) {
                start_offset = 1
            } else  if (valueState <= 10){
                start_offset = 1
            }
        } else {
            start_offset = offsetState + end_offset
        }
      
        if(static_counter) {
            start_offset = 0
        }
        
        return {
            end_offset:end_offset,
            start_offset: start_offset,
            interval: 200,
            increment: increment
        }
    }
    
    
    const generateValues = () => {
        let initial = valueState - getConfig().start_offset;
        
        const test:boolean = false
        const coreFunction = () => {
            if (!elem.current) return;
            if(!static_counter) initial += getConfig().increment;
            
            let valueRefactored = initial && numberFormatter("# ##0.00", initial.toFixed(2));
            
            if(!valueRefactored) {
                
                alert(`
                    value: ${value}
                    valueState ${valueState}
                    valueRefactored: ${valueRefactored}
                    initial: ${initial}
                    getConfig().increment ${getConfig().increment}
                    getConfig().start_offset ${getConfig().start_offset}
                `)
                
                clearInterval(interval.current)
                return
            }
            
            let splited = valueRefactored.split('');
            
            
            let valueRefactoredIncremented = initial && numberFormatter("# ##0.00", (initial + getConfig().increment).toFixed(2));
            let splitIncremented = valueRefactoredIncremented.split('');
            
            const fragment = document.createDocumentFragment();
            
            if(initial >= valueState - getConfig().end_offset) {
                clearInterval(interval.current)
                
                splited.forEach((x: any, i:number) => {
                    
                    const innerDiv = document.createElement('div');
                    const div = document.createElement('div');
                    div.className = clsx(styles.value);
                    
                    const slideInnerDiv = document.createElement('div');
                    
                    slideInnerDiv.textContent = x;
                    
                    innerDiv.appendChild(slideInnerDiv);
                    
                    const minWidthFactor = (x === "." || x === " ") ? 0.3 : 0.1;
                    let w = on_jp_page ? (window.innerWidth < 600 ? 4 : textWidth - (textWidth * minWidthFactor)) : textWidth - (textWidth * minWidthFactor)
                    innerDiv.style.minWidth = `${w}px`;
                    innerDiv.style.textAlign = 'center';
                    innerDiv.style.transition = 'none';
                    innerDiv.style.animationName = '';
                   
                    div.appendChild(innerDiv);
                    fragment.appendChild(div);
                });
                elem.current.innerHTML = '';
                elem.current.appendChild(fragment);
                return;
            }
            
            
            if(splitIncremented.length > splited.length) {
                splitIncremented = splitIncremented.slice(1)
            }
            
            
            splited.forEach((x: any, i:number) => {
                
                const innerDiv = document.createElement('div');
                innerDiv.className = styles.slide;
                if(!test) {
                    if (splitIncremented[i] !== x) {
                        innerDiv.classList.add(styles.rolling)
                    } else {
                        innerDiv.classList.remove(styles.rolling)
                    }
                }
                
                
                const div = document.createElement('div');
                
                div.className = clsx(styles.value);
                
                const slideInnerDiv = document.createElement('div');
                const slideInnerDiv1 = document.createElement('div');
                slideInnerDiv.textContent = x;
                slideInnerDiv1.textContent = (splitIncremented[i] !== " " || splitIncremented[i] !== ".") ?  splitIncremented[i] : "";
                innerDiv.appendChild(slideInnerDiv);
                innerDiv.appendChild(slideInnerDiv1);
                const minWidthFactor = (x === "." || x === " ") ? 0.3 : 0.1;
                let w = on_jp_page ? (window.innerWidth < 600 ? 4 : textWidth - (textWidth * minWidthFactor)) : textWidth - (textWidth * minWidthFactor)
                innerDiv.style.minWidth = `${w}px`;
                innerDiv.style.textAlign = 'center';
                innerDiv.style.transition = '0.2s all';
                
                div.appendChild(innerDiv);
                fragment.appendChild(div);
            });
            
            // Clear the current content and append the new elements
            elem.current.innerHTML = '';
            elem.current.appendChild(fragment);
            
        }
        
        coreFunction()
       
        if(!test || !static_counter) {
            interval.current = setInterval(() => {
                coreFunction()
            }, getConfig().interval);
        }
    };
    
    useEffect(() => {
        
        
        if(valueState) {
            generateValues()
        }
        
    }, [valueState]);
    
    useEffect(() => {
        return () => {
            clearInterval(interval.current)
        }
    }, []);
    
    useEffect(() => {
        
        if(value !== valueState) {
            clearInterval(interval.current)
            setOffsetState(value - valueState)
            
            setValueState(value)
        }
        
    }, [jackpot]);

    return (
        <div
            ref={elem}
            id={id}
            style={style ? style : {}}
            className={className}
        />
    )
};

export default JackpotCounterDynamic
