import Image from 'next/image';
import Link from 'next/link';
import {useRouter} from 'next/router';
import useAuth from "hooks/auth";
import strings from 'utils/strings';
import clsx from 'clsx';
import {Tooltip} from '@mui/material';
import {ClickAwayListener} from '@mui/base';
import React, {useState, useEffect, useRef} from 'react';
import {openDeposit, openLoginModal} from "store/modals/actions";
import {useDispatch, useSelector} from 'react-redux';
import styles from '@/template/styles/balance.module.scss'
import candyQuestLvs from 'public/json/candy_quest.json';
import {navigateToPendingTickets} from "store/common/actions";
import {getDocumentValidation, getWithdrawal} from "services/account";
import moment from 'moment';
import {validationTypes} from "utils/constants";
import CMS from "@/AbsoluteComponents/utils/cms";
import {bonus_types} from "@/AbsoluteComponents/interfaces/types";
import DummyRange from "@/template/ui/DummyRange";
import useCommon from "@/AbsoluteComponents/hooks/common";
import InfoIcon from "@/template/svg/info";
import Game from "@/AbsoluteComponents/utils/game";
import GameInterface from "@/AbsoluteComponents/interfaces/game";
import Card from "@/template/ui/game/card";
import Chevrone from "@/template/svg/Chevrone";
import Button from "@/template/small_ui/button";
import NewArrow from "@/template/svg/newArrow";


const DefaultBalanceView:React.FC= () => {
    const data = useAuth();
    const router = useRouter();
    const progress = data.getWithdrawProgress();
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [transactionsCount, setTransactionsCount] = useState(0);
    const betBalance = useSelector((state:any) => state.account.betBalance)
    const leaderboard: any = useSelector((state:any) => state.account.questLeaderboard)


    const getPendingWithdraw = async () => {
        const data = await getWithdrawal(0, 10, 'pending', moment().subtract(2, 'days').format('YYYY-MM-DD HH:mm:ss'),  moment().format('YYYY-MM-DD HH:mm:ss'));

        if (!data.error) {
            setTransactionsCount(data.response?.transactions?.length || 0)
        }
    };

    const openPendingWithdraw = () => {
        router.push('/account/transactions/withdrawal?status=pending');
    };

    useEffect(() => {
        setOpen(false);
    }, [router.pathname]);

    useEffect(() => {
        if (open) {
            getPendingWithdraw();
            if(window.innerWidth < 700) {
                document.body.classList.add('hideZendesk');
            }
        } else {
            setTransactionsCount(0);
            if(window.innerWidth < 700) {
                document.body.classList.remove('hideZendesk');
            }
        }
    }, [open]);


    const freeBonus = data.getFreeBonuses();
    const freeSpins =  data.getFreeSpins();
    
    let bonus = Number(localStorage.getItem('bonus') || 0);
    let spins = Number(localStorage.getItem('spins') || 0);

    let specialOffer =  ((freeBonus > bonus) || (freeSpins > spins));

    const changeClass = () => {
        localStorage.setItem('bonus', freeBonus.toString());
        localStorage.setItem('spins', freeSpins.toString());
    }


    // const [documents, setDocument] = useState([]);

    // const fetchValidation = async () => {
    //
    //     const result:any = [];
    //
    //     const documents = await getDocumentValidation();
    //
    //     if(!documents.error) {
    //         documents.response.map((item:any) => {
    //             result.push({
    //                 documentName: typeof item['documentName'] === 'string' ? item['documentName'] : '',
    //                 status: typeof item['status'] === 'string' ? item['status'] : '',
    //                 type: typeof item['type'] === 'string' ? item['type'] : '',
    //                 updatedTime: typeof item['updatedTime'] === 'number' ? item['updatedTime'] : 0,
    //                 uploadedTime: typeof  item['uploadedTime'] === 'number' ? item['uploadedTime'] : 0,
    //                 verificationType:typeof  item['verificationType'] === 'string' ? item['verificationType'] : 0,
    //             })
    //         })
    //
    //         setDocument(result);
    //     }
    // }


    useEffect(() => {
        // fetchValidation()
    }, []);
    

    // const types:Array<string> = validationTypes.map(item => item.key);

    // let approved = documents.find((item:any) => {
    //     return item.status === 'approved' && types.includes(item.type) ;
    // });

    // const status:boolean = documents.length === 0 ? true : approved !== undefined;
    const status:boolean = data.getUser().kycVerified

    //ADDED START  ADDED START  ADDED START  ADDED START  ADDED START  ADDED START

    let userCandyStatus = data.getCandyQuestStatus()


    let currentLevel = candyQuestLvs.find((lv:any) => {
        return lv.Nivel === userCandyStatus.level
    })

    const getPercentage = (): number => {
        let questPercentage:number = 0;
        if(currentLevel && currentLevel.Nivel !== 40 ) {
            let minPoints:number = currentLevel.Puncte;

            let maxPoints:any = candyQuestLvs[userCandyStatus.level].Puncte

            let currentLvTotalPoints:number = maxPoints - minPoints;

            let currentLvUserPoints:number = userCandyStatus.points - minPoints;
            let percent:number = currentLvTotalPoints / 100;
            questPercentage = parseFloat((currentLvUserPoints/percent).toFixed(2))
        } else if (currentLevel && currentLevel.Nivel === 40) {
            questPercentage = 100
        }

        return questPercentage
    }
   
    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <div className={clsx(styles.dropDownBalanceWrapper, open && styles.dropDownBalance_active)}>

                <button
                    id="myDIV"
                    className={clsx(styles.btn_balance, !status && styles.btnBalance_NotValidation, (status  && specialOffer) && styles.btnBalance_activeSpins)}
                    onClick={() => {setOpen(!open), changeClass()}}
                >
                    <span className={clsx(styles.useBalanceWrapper)}>

                        <span className={styles.useBalance}>
                            {data.getUserBalance()}
                        </span>

                        <span className={styles.balanceCurrency} >
                            {data.getCurrency()}
                        </span>

                    </span>

                    <Image onClick={(e) => {
                        e.stopPropagation();
                        dispatch(openDeposit(true));
                    }} style={{border: '1px solid transparent'}} className={clsx(styles.btn_balance_add, 'btn-balance__add')} src={'/default/icons/AddNew.svg'} alt={'img'} width={48} height={48}/>
                </button>

                <div className={clsx(styles.dropDownBalance)}>
                    <div className={styles.dropDownBalance__items}>
                        <p className={styles.dropDownBalance__title}>{strings['balanta']}</p>
                        <p className={styles.dropDownBalance__data}>{ data.getUserBalance()}
                            <span className={styles.dropDownBalance__currency}>
                                {data.getCurrency()}
                            </span>
                        </p>
                    </div>

                    <div className={styles.dropDownBalance__cash}>
                        
                        <div className={styles.dropDownBalance__cashAm} style={{display:'flex',  justifyContent: 'space-between', alignItems: 'center', WebkitAlignItems: 'center'}}>
                            <p className={styles.dropDownBalance__title}>{strings['bani_cash']}</p>
                            <p className={styles.dropDownBalance__data}>{data.getCash()}
                                <span className={styles.dropDownBalance__currency}>
                                    {data.getCurrency()}
                                </span>
                            </p>
                        </div>
                        
                        {(typeof data.getBonus() === "number" && data.getBonus() === 0) && (
                            <div className={styles.dropDownBalance__items}>
                                <p className={styles.dropDownBalance__title}>{strings['bonus']} </p>
                                <p className={styles.dropDownBalance__data}>
                                    {data.getBonus()}
                                    <span className={styles.dropDownBalance__currency}>
                                        {data.getCurrency()}
                                    </span>
                                </p>
                            </div>
                        )}
                        
                        <Button
                            className={styles.deposit_btn}
                            text={ strings["deposit"]}
                            onClick={() => {
                                dispatch(openDeposit(true))
                                setOpen(false)
                            }}
                        />

                        {/*ADDED START ADDED START ADDED START ADDED START ADDED START */}
                        
                        {/*<div  className={styles.dropDownBalance__progressBlock}>*/}
                        {/*    */}
                        {/*    <div className={styles.dropDownBalance__wrappers}>*/}
                        {/*        <p className={clsx(styles.dropDownBalance__title)}> {strings['rulaj_necesar']}</p>*/}
                        {/*        <Tooltip*/}
                        {/*            placement={'left'}*/}
                        {/*            enterTouchDelay={0}*/}
                        {/*            title={'Poate fi retras la 100%'}*/}
                        {/*        >*/}
                        
                        {/*            <span>*/}
                        {/*                <img src={'/default/icons/help.svg'}  width={20} height={20} alt={'img'}/>*/}
                        {/*            </span>*/}
                        
                        {/*        </Tooltip>*/}
                        {/*    </div>*/}
                        
                        {/*    <div className={styles.dropDownBalance__progress}>*/}
                        {/*        <p className={styles.progressData}>{ progress?.toFixed(2) || 0}%</p>*/}
                        {/*        <div style={{height: 24, background: '#EEB665', width: `${progress}%`}}/>*/}
                        {/*    </div>*/}
                        {/*    */}
                        {/*</div>*/}

                        {/*ADDED END ADDED END ADDED END ADDED END ADDED END */}

                    </div>

                    {/*ADDED START  ADDED START  ADDED START  ADDED START  ADDED START  */}

               
                    {(data.getQuestStatus().hasOwnProperty("optIn") && data.getQuestStatus().optIn ) && (
                        <div className={clsx(styles.questBalance, styles.clickable)}>
                            
                            <div onClick={() => {
                                router.push('/'+CMS.getCurrentQuest().url_id)
                            }} className={clsx(styles.questBalance__section, styles.section1)}>
                                <div className={styles.questBalance__headings}>
                                    <h4 style={{textTransform: "capitalize"}}>
                                        {CMS.getCurrentQuest().name}
                                    </h4>
                                    <span className={styles.score}>
                                        <span className={styles.yellow}>
                                            {userCandyStatus?.level || 0}
                                        </span>
                                        /{Object.keys(leaderboard.leaderBoard) && Object.keys(leaderboard.leaderBoard)[Object.keys(leaderboard.leaderBoard).length - 1] }
                                    </span>
                                
                                </div>
                                
                                <div style={{margin: 0}} className={'dropDownBalance__progress'}>
                                    
                                    <p style={{margin: 0}} className={'progressData'}>
                                        Nivelul {data.getQuestStatus().level}
                                        
                                        <span style={{marginLeft: '10px'}}>
                                            {data.currentUserQuestPercentage()}%
                                        </span>
                                    </p>
                                    
                                    <div style={{height: 24, background: '#EEB665', width: `${data.currentUserQuestPercentage()}%`}}/>
                                </div>
                            </div>
                        
                        </div>
                    )}
                    
                    {/*ADDED END  ADDED END  ADDED END  ADDED END  ADDED END  ADDED END  */}
                    
                    
                    <BonusList data={data} />
                    
                    {betBalance > 0 ? (
                        <div
                            onClick={() => {
                                router.push('/account/transactions/betting?status=pending')
                                dispatch(navigateToPendingTickets(true))
                            }}
                            className={clsx(styles.dropDownBalance__items, 'clickable')}
                            style={{cursor: 'pointer'}}
                        >
                            
                            <p className={styles.dropDownBalance__title}>
                                {strings['balanta-pariuri']}
                            </p>
                            
                            <p className={styles.dropDownBalance__data}>
                                {betBalance}
                                <span className={styles.dropDownBalance__currency}>
                                    {data.getCurrency()}
                                </span>
                            </p>
                        
                        </div>
                    
                    ) : null}

                    {!status &&
                        <Link href={'/account/account-validation'} className={clsx(styles.balanceValidation, styles.dropDownBalance__items)}>

                            <p style={{color: 'red', margin: '8px 0', fontWeight: 600}} className={styles.dropDownBalance__title}>
                                {strings["ver_identity"]}
                            </p>

                        </Link>
                    }

                    {transactionsCount > 0 &&
                        <div style={{display: "flex"}} className={styles.dropDownBalance__withdraw}>
                            <div className={styles.dropDownBalance__title}>
                                Retrageri în așteptare
                            </div>
                            
                            <button
                                type={'button'}
                                className={'dropDownBalance__btn'}
                                onClick={openPendingWithdraw}
                            >
                                Detalii ({transactionsCount})
                            </button>
                        
                        </div>
                    }
                
                </div>
            </div>
        </ClickAwayListener>
    )
}

interface BonusListProps {
    data:any
}

const BonusItem: React.FC<{
    title: string;
    value: number | string;
    onClick: () => void;
    bonusContainerRef: React.RefObject<any>;
    bonusClassName: string;
    dataStyleClass: string;
    type:bonus_types
    bonus_obj:any
}> = (props) => {
    
    const {
        title,
        value,
        onClick,
        bonusContainerRef,
        bonusClassName,
        dataStyleClass,
        type,
        bonus_obj
    } = props
    
    return (
        
        <div ref={bonusContainerRef} className={styles.main_item_component}>
            <div onClick={onClick}  className={clsx(styles.dropDownBalance__items, bonusClassName)} title={title}>
                
                <div className={styles.left}>
                    <NewArrow />
                    
                    <p style={{ color: '#EEB665' }} className={styles.dropDownBalance__title}>
                        {title}
                    </p>
                </div>
                
                
                <span className={clsx(styles.dropDownBalance__data, styles.dropDownBalance__dataStyle, dataStyleClass)}>
                    {value}
                </span>
                
            </div>
            
            <div className={styles.bonus_container}>
                <BonusView bonus_obj={bonus_obj} type={type as bonus_types} />
            </div>
        </div>
    )
}

const BonusView: React.FC <{type:bonus_types, bonus_obj:any}> = ({type, bonus_obj}) => {
    
    const common_hook = useCommon()
    const auth = useAuth()
    const router = useRouter()
    
    switch (type){
        case bonus_types.Bonus:
            
            return (
                <div className={styles.bonus_type_bonus}>
                    {(Array.isArray(bonus_obj) && bonus_obj.length) ? bonus_obj.map((x, i) => {
                        
                        const percentage = common_hook.getPercentage(0, x?.turnOverThreshold / 100, x?.turnOver / 100)

                        return (
                            <div
                                key={`balance_bonus_${i}`}
                                className={styles.bonuses}
                            >
                                <div className={styles.range}>
                                    <span>
                                        Bonus {i + 1} - <span className={styles.yellow}>{percentage || 0}%</span>
                                    </span>
                                    
                                    <DummyRange
                                        no_paddings
                                        max={(x?.turnOverThreshold / 100) || 0}
                                        value={(x?.turnOver / 100) || 0}
                                        denyValueLabel
                                    />
                                </div>
                                
                                <span className={clsx(styles.yellow, styles.amn)}>
                                    {x?.amount.toFixed(2)} RON
                                </span>
                            </div>
                        )}
                    ) : null}
                    
                    <span className={styles.inf}>
                        
                        <InfoIcon /> Bonusurile vor fi plătite în proporție de 100%.
                    </span>
                </div>
            )
        
        case bonus_types.FreeBets:
            return (
                <div className={styles.bonus_type_free_bets}>
                    {(Array.isArray(bonus_obj) && bonus_obj.length) ? bonus_obj.map((x, i) => {
                        
                        return (
                            <div
                                key={`balance_free_bet_${i}`}
                                className={styles.bet}
                                onClick={() => router.push("/sport")}
                            >
                                
                                <div className={styles.ticket_start}>
                                    
                                    <Image
                                        src={'/default/icons/icon_sport.svg'}
                                        alt={''}
                                        width={24}
                                        height={24}
                                    />
                                    
                                    <span className={clsx(styles.cuts, styles.top)}/>
                                    
                                    <span className={clsx(styles.cuts, styles.bottom)}/>
                                
                                </div>
                                
                                <div className={styles.value}>
                                    {x?.accountingExtraData?.betAmount.toFixed(2)} RON
                                </div>
                                
                                <div className={styles.badge}>
                                    {x?.grantedAmount}
                                </div>

                            </div>
                        )
                    }) : null}
                </div>
            )
        
        case bonus_types.TimedFreeSpins:
            
            return (
                <div className={styles.bonus_type_free_spins}>
                    
                    
                    
                    {(Array.isArray(bonus_obj) && bonus_obj.length) ? bonus_obj.map((x, i) => {
                        
                        const game: any = Game.getAll().find((g: GameInterface) => g.gameId === x.games[0]?.gameId);

                        return game ? (
                            <div
                                key={`balance_free_spins_${i}`}
                                className={styles.game}
                                onClick={(e: any) => auth.runGame(game, false, () => {
                                }, e)}
                            >
                                <Card
                                    disableDemo
                                    hideDetails={true}
                                    hide_inner
                                    game={game}
                                />
                                
                                <div className={clsx(styles.badge, styles.g)}>
                                    {x?.interval} sec.
                                </div>
                            </div>
                        ) : null
                    }) : null}
                </div>
            )
        
        case bonus_types.FreeSpins:
            return (
                <div className={styles.bonus_type_free_spins}>
                    {(Array.isArray(bonus_obj) && bonus_obj.length) ? bonus_obj.map((x, i) => {
                        
                        const game: any = Game.getAll().find((g: GameInterface) => g.gameId === x.games[0]?.gameId);
                        
                        return (
                            <div
                                key={`balance_free_spins_${i}`}
                                className={styles.game}
                                onClick={(e: any) => auth.runGame(game, false, () => {
                                }, e)}
                            >
                                <Card
                                    disableDemo
                                    hideDetails={true}
                                    hide_inner
                                    game={game}
                                />
                                
                                <div className={clsx(styles.badge, styles.g)}>
                                    {x?.grantedAmount}
                                </div>
                            </div>
                        )
                    }) : null}
                </div>
            )
        
        case bonus_types.FreeBonus:
            
            const game: any = bonus_obj?.game ? Game.getAll().find((g: GameInterface) => g.gameId === bonus_obj?.game?.gameId) : undefined
            
            
            return (
                <div className={styles.bonus_type_free_spins}>
                    {(Array.isArray(bonus_obj) && bonus_obj.length) ? bonus_obj.map((x, i) => {
                        
                        const game: any = Game.getAll().find((g: GameInterface) => g.gameId === x.game.gameId);
                        
                        return game ? (
                            <div
                                key={`balance_free_spins_${i}`}
                                className={styles.game}
                                onClick={(e: any) => auth.runGame(game, false, () => {}, e)}
                            >
                                <Card
                                    disableDemo
                                    hideDetails={true}
                                    hide_inner
                                    game={game}
                                />
                                
                                <div className={clsx(styles.badge, styles.g)}>
                                    {x?.grantedAmount}
                                </div>
                            </div>
                        ) : null
                    }) : null}
                </div>
            )
        default:
            return <></>
    }
}

const BonusList: React.FC<BonusListProps> = ({data}) => {
    const ref0 = useRef<any>(null);
    const ref1 = useRef<any>(null);
    const ref2 = useRef<any>(null);
    const ref3 = useRef<any>(null);
    const ref4 = useRef<any>(null);
    const new_wallet = useSelector((state: any) => state.account.new_wallet);
    
    const bonusItems = [
        {
            visible: Array.isArray(new_wallet?.bonuses) && new_wallet?.bonuses.length,
            title: "Bonus",
            value: (() => {
                try {
                    if (Array.isArray(new_wallet?.bonuses)) {
                        return new_wallet?.bonuses.reduce((sum: any, x: any) => sum + x?.amount, 0)
                    } else return 0
                    
                } catch (e) {
                    console.error(e)
                    return 0
                }
                
            })().toFixed(2) + " RON",
            bonusClassName: styles.firstStyle,
            bonusContainerRef: ref0,
            dataStyleClass: Array.isArray(new_wallet?.bonuses) && new_wallet?.bonuses.length ? styles.dropDownBalance__dataStyle : "",
            type: bonus_types.Bonus,
            bonus_obj: new_wallet?.bonuses
            
        },
        {
            visible: data.getFreeSpins() > 0,
            title: strings['rotiri_gratuite'],
            value: data.getFreeSpins(),
            bonusClassName: styles.spinsStyle,
            bonusContainerRef: ref1,
            dataStyleClass: data.getFreeSpins() === 0 ? '' : styles.dropDownBalance__dataStyle,
            type: bonus_types.FreeSpins,
            bonus_obj: new_wallet?.freeSpins
        },
        {
            visible: data.getFreeBonuses() > 0,
            title: 'Speciale Gratuite',
            value: data.getFreeBonuses(),
            bonusClassName: styles.bonusStyle,
            bonusContainerRef: ref2,
            dataStyleClass: data.getFreeBonuses() === 0 ? '' : styles.dropDownBalance__dataStyle,
            type: bonus_types.FreeBonus,
            bonus_obj: new_wallet?.freeBonuses
        },
        {
            visible: data.getFreeBets() > 0,
            title: strings['pariuri_gratuite'],
            value: data.getFreeBets(),
            bonusClassName: styles.betStyle,
            bonusContainerRef: ref3,
            dataStyleClass: data.getFreeBets() === 0 ? '' : styles.dropDownBalance__dataStyle,
            type: bonus_types.FreeBets,
            bonus_obj: new_wallet?.freeBets
        },
        {
            visible: data.getTimedFreeSpins() > 0,
            title: strings['rotiri_gratuite_pe_timp'],
            value: `${data.getTimedFreeSpins()} sec.`,
            bonusClassName: styles.spinsStyle,
            bonusContainerRef: ref4,
            dataStyleClass: styles.dropDownBalance__dataStyle,
            type: bonus_types.TimedFreeSpins,
            bonus_obj: new_wallet?.timedFreeSpins
        }
    ];
    
    const atLeastOneVisible = bonusItems.some(item => item.visible);
    
    return (
        
        <>
            {atLeastOneVisible ? (
                <span className={styles.bonus_text}>
                    Bonusurile tale:
                </span>
            ) : null}
            
            <div className={styles.bonus_list}>
                {bonusItems.map((item, index) =>
                    item.visible ? (
                        <React.Fragment key={`balance_bonus_${index}`}>
                            <BonusItem
                                title={item.title}
                                value={item.value}
                                onClick={() => {
                                    item.bonusContainerRef.current?.classList.toggle(styles.open)
                                }}
                                bonusContainerRef={item.bonusContainerRef}
                                bonusClassName={item.bonusClassName}
                                dataStyleClass={item.dataStyleClass}
                                type={item.type}
                                bonus_obj={item.bonus_obj}
                            />
                        </React.Fragment>
                    ) : null
                )}
            </div>
        </>
        
    );
};



export default DefaultBalanceView;
