import {createTheme} from "@mui/material/styles";

export default createTheme({
    components: {
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    color: '#84789C',
                    marginRight: 10,

                    '&.MuiSvgIcon-fontSizeMedium' : {
                        marginRight: 0,
                        color: '#84789C',
                    },
                },
            },
        },

        MuiFormControl: {
            styleOverrides: {
                root: {
                    '&.datepicker': {
                        ['.MuiInputBase-root']: {
                            // background: '#19131d',
                            fontFamily: "'Montserrat' , sans-serif",
                            fontStyle: 'normal',
                            background: '#1F1B24',
                            // border: '1px solid #35303e',
                            borderRadius: 60,
                            color: 'inherit',
                            textTransform: 'capitalize',
                            outline: 'none !important',
                            height: '100%',

                            '& input': {
                                padding: '20px 0px 8px 20px',
                                fontSize: 14,
                            },
                            '.MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                                // background: '#1F1B24'
                            },
                        },
                    }
                },
            },
        },


        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    color: '#EEB665',
                    // '&.Mui-selected': {
                    //     background: 'transparent'
                    // },

                    '&.candyQuestProgress': {
                        color: '#ca27fd',
                    }
                },

            }
        },

        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    color: '#84789C',
                    // background: 'red',
                    fontWeight: 400,
                    fontSize: 14,
                    minWidth: 20,
                    '&.Mui-selected': {
                        background: 'transparent',
                        "&:hover": {
                            backgroundColor: "transparent",
                        },
                    },
                    "&:hover": {
                        backgroundColor: "transparent",
                    },
                }
            }
        },

        MuiFormLabel: {
            styleOverrides: {
                root: {
                    // fontFamily: 'Montserrat',
                    // fontStyle: 'normal',
                    color: '#84789c',
                    paddingTop: 17,
                    paddingLeft: 12,
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root:{
                    fontSize: 12,
                    fontWeight: 500,
                    fontFamily: '"Montserrat", sans-serif',
                    textTransform: 'uppercase',
                    color: '#84789C',

                    '&.Mui-focused': {
                        color: 'white',
                    }
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: '#27222D',
                    borderRadius: 10,
                    fontFamily: 'Montserrat, sans-serif',
                    fontWeight: 500,
                    fontSize: 14,
                    lineHeight: "130%",
                    minHeight: 32,
                    padding: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    WebkitAlignItems: 'center',
                    textAlign: 'center'
                },
                
                popper: {
                    zIndex: "10"
                }
                
            }
        },

        MuiSkeleton: {
            styleOverrides: {
                root: {
                    '&.simpleImageSkeleton': {
                        background: '#1e1b25',
                        width: '165px',
                        height: '165px',
                        borderRadius: '20px',
                    },
                    '&.carImageSkeleton': {
                        background: '#1e1b25',
                        width: '320px',
                        height: '165px',
                        borderRadius: '20px',

                        '@media (max-width: 1200px)': {
                            width: '210px',
                            height: '110px',
                        },

                        '@media (max-width: 770px)': {
                            width: '320px',
                            height: '165px',
                        },

                        '@media (max-width: 640px)': {
                            width: '250px',
                            height: '133px',
                        },

                        '@media (max-width: 500px)': {
                            width: '210px',
                            height: '110px',
                        }
                    },

                    '&.autoLgSkeleton': {
                        width: '673px',
                        height: '288px',
                        background: '#1e1b25',
                        borderRadius: '20px',
                    },

                    '&.autoSmSkeleton': {
                        width: '220px',
                        height: '125px',
                        borderRadius: '20px',
                        background: '#1e1b25',
                        transform: 'translateX(-15px)',
                    },

                    '&.cardSkeleton': {
                        background: '#383838',
                        borderRadius: '5px',
                        marginRight: '20px',
                        width: '100%',
                        height: '100%',
                        aspectRatio: '100000/144421',
                        transform: 'scale(0.95)',
                        display: 'block',
                        '@media (max-width:991px)' : {
                            background: '#383838',
                            borderRadius: '5px',
                            display: 'block',

                        }
                    },

                    '&.skeletonGameCard': {
                        background: '#383838',
                        borderRadius: '5px',
                        marginRight: '20px',
                        width: '240px !important',
                        height: '356px',
                        transform: 'scale(0.90) translateX(3px)',
                        display: 'block',


                        '@media (max-width:991px)' : {
                            background: '#383838',
                            borderRadius: '5px',
                            width: '133px !important',
                            height: '212px',
                            display: 'block',
                        }
                    },

                    '&.categHeadingSkeleton': {
                        background: '#383838',
                        borderRadius: '5px',
                        width: '326.02px',
                        height: '36px',
                        display: 'block',

                        '@media (max-width:575px)': {
                            width: '269px',
                        },

                        '@media (max-width:361px)': {
                            width: '231px',
                        }
                    },

                    '&.categLeftFilterSkeleton': {
                        background: '#383838',
                        height: '56px',
                        borderRadius: '100px',
                        minWidth: '179px',
                        display: 'block',

                    },

                    '&.categRightFilterSkeleton': {
                        background: '#383838',
                        height: '56px',
                        borderRadius: '100px',
                        width: '252px',
                        display: 'block',
                    },

                    '&.themeCategorySkeleton': {
                        background: '#383838',
                        width: '100%',
                        height: '90%',
                        display: 'block',
                        borderRadius: '10px',
                    },

                    '&.tableSkeleton': {
                        height: '56px',
                        width: '100%',
                        display: 'block !important',

                        '&.tableSkeletonLight': {
                            background: '#17141B !important',
                        }
                    },

                    '&.skeletonJpCard': {
                        width: '292px',
                        height: '375px',
                        background: '#262626',
                        borderRadius: "24px",

                        '@media (max-width: 991px)': {
                            width: '150px',
                            height: '192px',
                            marginRight: '8px' ,
                        }

                    },

                    '&.mapSkeleton' : {
                        width: '764.37px',
                        height: '1620.54px',
                        background: '#262626',
                        borderRadius: '10px',
                        position: 'absolute',
                        top: 0,
                        left: 0,

                        '@media (max-width: 890px)': {
                            width: '428px',
                            minWidth: '428px',
                            height: '907.3px'
                        },
                    },

                    '&.candyMapSkeleton' : {
                        width: '670px',
                        height: '2420px',
                        background: '#2e0738',
                        borderRadius: '10px',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                    },


                    '&.winnersSliderSkeleton': {
                        width: '222px',
                        height: '16px',
                        borderRadius: '10px',
                        marginLeft: '25px',
                        marginBottom: '10px',

                        '@media (max-width: 575px)': {
                            width: '342px',
                            height: '29px',
                            borderRadius: '10px',
                            marginBottom: '10px',
                        },

                    }
                }
            }
        }
    }
});