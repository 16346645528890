import ServiceResponse from 'interfaces/service_response';
import strings from "utils/strings";
import qs from 'query-string';
import UserInterface from "interfaces/user";
import cashpotFetch from './cashpot_interceptor';
import {getAffiliateToken, getAffiliateId, getOptimizeToken, getAuthToken, getRefeshToken} from 'utils/cookies';
import process from "process";
import CMS from "@/AbsoluteComponents/utils/cms";
import Payment from "@/AbsoluteComponents/utils/payment";


const host:string = '/api';

const cashPotTrackQueryParams = (additionalParams:boolean):string => {
    const params:any = {};

    const getValue = (value:string):string => {
        try {
            const json:any = JSON.parse(decodeURIComponent(value));

            return typeof json.value === "string" ? json.value : '';
        } catch (e) {
            return '';
        }
    }

    const affiliate_id:string = getAffiliateId();
    const affiliate_token:string = getAffiliateToken();
    const optimize_token:string = getOptimizeToken();

    if (affiliate_id.length) params['affiliate_id'] = getValue(affiliate_id);
    if (affiliate_token.length) params['affiliate_token'] = getValue(affiliate_token);
    if (optimize_token.length) params['optimize_token'] = getValue(optimize_token);

    return (additionalParams ? '&' : '?') + qs.stringify(params);
};

const toggleFetch = (url:string, params:any) => {
    if (process.env.NODE_ENV !== 'production') {
        // From localhost
        // It can work just because the remote site permits through CORS
        return fetch(`/api${url}`, params);
    } else {
        // From BUILD
        return cashpotFetch(url, params);
    }
};

export const signInService = async (email: string, password: string): Promise<ServiceResponse> => {
    let response:ServiceResponse = {
        error: false,
    };

    try {
        const request = await toggleFetch('/v1/player/login', {
            method: 'post',
            body: JSON.stringify({
                email, password
            })
        });
        
        const body = await request.json();
        
        if(body.hasOwnProperty("extraData")) {
            response.error = true;
            response.response = body;
        }
        
        if(body["code"] === "403" && body.hasOwnProperty("extraData")) {
            response.message = strings["account_is_blocked"];
            response.error = true
            
            return response
        }
        
        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message = body['message'];
            
        } else if (body['accessToken'] === undefined) {
            response.error = true;
            response.message = strings['unknown_error'];
            
        } else {
            response.response = body;
            
        }

        return response;
        
    } catch (error) {
        response.error = true;
        response.message = error!.toString();

        return response;
    }
}

export const reopen_account = async (email: string, password: string): Promise<ServiceResponse> => {
    
    let response:ServiceResponse = {
        error: false,
    };
    
    try {
        const request = await toggleFetch('/v1/player/reopenAccount', {
            method: 'post',
            body: JSON.stringify({
                email, password
            })
        });
        
        const body = await request.json();
        
        response.response = body;
        
        return response;
        
    } catch (error) {
        response.error = true;
        response.message = error!.toString();
        
        return response;
    }
}

export const failedLogin = async (phoneNumber: string): Promise<ServiceResponse> => {
    let response:ServiceResponse = {
        error: false,
    };
    
    try {
        const request = await toggleFetch('/v1/player/failedLogin', {
            method: 'post',
            body: JSON.stringify({
                phoneNumber
            })
        });
        
        response.status = request.status
        
        const body = await request.json();
        
        if(body.hasOwnProperty("success") && body.success) {
            response.response = body;
            response.message = strings['code_sent'] + phoneNumber;
        }
        
        if(!body.hasOwnProperty("success")){
            response.error = true;
            response.message = strings['not_existing_nr'];
        }

        return response;
        
    } catch (error) {
        response.error = true;
        response.message = error!.toString();
        
        return response;
    }
}

export const phoneVerify = async (code:string ,phoneNumber: string): Promise<ServiceResponse> => {
    let response:ServiceResponse = {
        error: false,
    };
    
    try {
        const request = await toggleFetch('/v1/player/failedLogin/verify', {
            method: 'post',
            body: JSON.stringify({
                phoneNumber,
                code
            })
        });
        
        response.status = request.status
        
        const body = await request.json();
        
        if(body.hasOwnProperty("accessToken")) {
            response.response = body
            response.error = false
            response.message = "Succes!"
        } else {
            response.error = true
            response.message = "Eroare! Codul a fost introdus greșit sau a fost depășit numărul de încercări!"
        }
  
        return response;
        
    } catch (error) {
        response.error = true;
        response.message = error!.toString();
        
        return response;
    }
}

export const changePwd2 = async (newPassword:string, token: string): Promise<ServiceResponse> => {
    let response:ServiceResponse = {
        error: false,
    };
    
    try {
        const request = await toggleFetch('/v2/player/account/profile/changePassword', {
            method: 'post',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                newPassword,
            })
        });
        
        response.status = request.status
        
        const body = await request.json();
        
        response.response = body
        
        if(body.hasOwnProperty("success") && body.success) {
            response.error = false
            response.message = "Succes!"
        } else {
            response.error = true
            response.message = "Eroare! Parola nu a fost validată!"
        }
        
        return response;
        
    } catch (error) {
        response.error = true;
        response.message = error!.toString();
        
        return response;
    }
}

export const signUpService = async ({
            email = '',
            password = '',
            promoCode = '',
            firstName = '',
            lastName = '',
            phoneNumber = '',
            cnp = '',
            address = '',
            userName = '',
            city = '',
            ageChecked = true,
            policyChecked = false,
            receiveSms = true,
            receiveEmail = true,
        }): Promise<ServiceResponse> => {
    let response:ServiceResponse = {
        error: false,
    };

    const nationalId:any = {
        "type": "cnp",
        "value": cnp
    };

    const addressInfo:any = {
        "address": address,
        "city": city,
    };

    const query:any = {};

    if (promoCode && promoCode.length) {
        query['affiliate_code'] = promoCode;
    }

    const queryString = Object.keys(query).length ? '?' + qs.stringify(query) : '';

    const trackParams:string = cashPotTrackQueryParams(Object.keys(query).length > 0);

    try {
        
        const request = await toggleFetch('/v2/player/register'+queryString+trackParams, {
        // const request = await toggleFetch('/v1/player/register'+queryString+trackParams, {
            method: 'post',
            body: JSON.stringify({
                email,
                password,
                firstName,
                lastName,
                userName,
                phoneNumber,
                nationalId: cnp,
                nationalCnp: cnp,
                address,
                city,
                ageChecked,
                policyChecked,
                receiveSms,
                receiveEmail,
            })

            // body: JSON.stringify({
            //     email, password, firstName, lastName, userName, phoneNumber, nationalId, address: addressInfo, ageChecked, policyChecked, receiveSms, receiveEmail
            // })
        });

        const body = await request.json();

        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message = body['message'];

            if (Array.isArray(body['details'])) {
                response.details = body['details'];
            }
        } else if (body['accessToken'] === undefined) {
            response.error = true;
            response.message = strings['unknown_error'];
        } else {
            response.response = body;
        }

        return response;
    } catch (error) { //console.log('error',error)
        response.error = true;
        response.message = error!.toString();

        return response;
    }
}

export const getProfile = async (token:string): Promise<ServiceResponse> => {

    let response:ServiceResponse = {
        error: false,
    };

    if (!token.length) {
        response.error = true;
        response.message = '';

        return response;
    }

    try {
        const request = await toggleFetch('/v1/player/account/profile', {
            method: 'get',
            // body: JSON.stringify({})
        });

        const body = await request.json();

        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message = body['message'];
        } else {
            response.response = body;
        }

        return response;
    } catch (error) { //console.log('error',error)
        response.error = true;
        response.message = error!.toString();

        return response;
    }
}


export const testApi = async (): Promise<ServiceResponse> => {
    let response:ServiceResponse = {
        error: false,
    };

    try {
        const request = await fetch(host + '/v1/test1/test2?get1=1&get2=2', {
            // method: 'get'
            method: 'post',
            body: JSON.stringify({
                test: 'test'
            })
        });

        const body = await request.json();

        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message = body['message'];
        } else {
            response.response = body;
        }

        return response;
    } catch (error) { //console.log('error',error)
        response.error = true;
        response.message = error!.toString();

        return response;
    }
}

export const getDocumentHistory = async (): Promise<ServiceResponse> => {

    let response:ServiceResponse = {
        error: false,
    };

    try {
        const request = await toggleFetch( '/v1/player/verification/document/history', {
            method: 'get',
        });

         const body = await request.json();

         if (body['code'] !== undefined && body['message'] !== undefined ) {
             response.error = true;
             response.message =  body['message'];
         } else {
             response.response = body;
         }

         return response;
    } catch (error) {
        response.error =  true;
        response.message = error!.toString();

        return response;
    }

}

export const getBalance = async (): Promise<ServiceResponse> => {

    let response:ServiceResponse = {
        error: false,
    };

    try {
        const request = await toggleFetch( '/v1/player/balance', {
            method: 'get',
        })

        const body = await request.json();

        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message =  body['message'];
        } else {
            response.response = body;
        }

        return response;

    } catch (error) {
        response.error =  true;
        response.message = error!.toString();

        return response
    }

}

export const changePassword =  async (newPassword:string, oldPassword:string,  ): Promise<ServiceResponse> => {

    let response:ServiceResponse = {
        error: false,
    };

    try {
        const request = await toggleFetch( '/v1/player/account/profile/changePassword', {
            method: 'post',
            body: JSON.stringify({
                oldPassword,  newPassword,
            })
        })

        const body = await request.json();
        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message =  body['message'];
        } else {
            response.response = body;
        }

        return response;

    } catch (error) {
        response.error =  true;
        response.message = error!.toString();

        return response
    }
}

export const getActivityAccount = async (offset:number, limit:number): Promise<ServiceResponse> => {
    let response:ServiceResponse = {
        error: false,
    };

    try {
        const request = await toggleFetch( `/v1/player/activity/account?offset=${offset}&limit=${limit}`, {
        // const request = await fetch( `/api/v1/player/activity/account?offset=${offset}`, {
        method: 'get',
    })

        const body = await request.json();

        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message =  body['message'];
        } else {
            response.response = body;
        }

        return response;

    } catch (error) {
        response.error =  true;
        response.message = error!.toString();

        return response
    }
}

export const getGame = async (offset:number , limit:number, /*status:string, gameId:string, period:string,*/ dateFrom:string, dateTo:string): Promise<ServiceResponse> => {
    let response:ServiceResponse = {
        error: false,
    };

    try {
        const params:any = {
            offset: offset,
            limit: limit,

        };

        if(status.length > 0) {
            params['status'] = status;
        }

        // if(gameId.length > 0) {
        //     params['gameId'] = gameId;
        // }
        //
        // if(period.length > 0) {
        //     params['period'] = period;
        // }

        if(dateFrom.length > 0){
            params['dateFrom'] = dateFrom;
        }

        if(dateTo.length > 0){
            params['dateTo'] = dateTo;
        }

        const request = await toggleFetch( `/v1/player/activity/game?`+qs.stringify(params), {
            method: 'get',
        })

        const body = await request.json();

        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message =  body['message'];
        } else {
            response.response = body;
        }

        return response;

    } catch (error) {
        response.error =  true;
        response.message = error!.toString();

        return response
    }
}



export const getSports = async (
        offset:number ,
        limit:number,

        gameType:string,
        gameId:string,
        // period:string,
        dateFrom:string,
        dateTo:string,
        status?:string,
    ): Promise<ServiceResponse> => {

    let response:ServiceResponse = {
        error: false,
    };

    try {
        const params:any = {
            offset: offset,
            limit: limit,
            status: status
        };

        // if(status.length > 0) {
        //     params['status'] = status;
        // }

        if(gameType.length > 0) {
            params['gameType'] = gameType;
        }

        if(gameId.length > 0) {
            params['gameId'] = gameId;
        }

        // if(period.length > 0) {
        //     params['period'] = period;
        // }

        if(dateFrom.length > 0){
            params['dateFrom'] = dateFrom;
        }

        if(dateTo.length > 0){
            params['dateTo'] = dateTo;
        }

        const request = await toggleFetch( `/v1/player/activity/game?`+qs.stringify(params), {
            method: 'get',
            headers: {
                'Authorization': `Bearer ${getAuthToken()}`,
                // "sw_referer": "cashpot.ro",
                // "x-site-id": "5cdd8a57-21a6-4c4b-98e8-ee7512d04440",
                // 'Access-Control-Allow-Origin': '*',
                // 'Accept': "application/json",
            }
        })

        const body = await request.json();

        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message =  body['message'];
        } else {
            response.response = body;
        }

        return response;

    } catch (error) {
        response.error =  true;
        response.message = error!.toString();

        return response
    }
}

export const getGameHistory = async (): Promise<ServiceResponse> => {
    let response:ServiceResponse = {
        error: false,
    };

    try {
        const request = await toggleFetch( `/v1/player/activity/gamesHistory`, {
            method: 'get',
        })

        const body = await request.json();

        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message =  body['message'];
        } else {
            response.response = body;
        }

        return response;

    } catch (error) {
        response.error =  true;
        response.message = error!.toString();

        return response
    }
}

export const getDeposit = async (offset:number, limit:number, status:string, pickerFrom:string, pickerTo:string): Promise<ServiceResponse> => {
    let response:ServiceResponse = {
        error: false,
    };


    try {

        const params:any = {
            offset: offset,
            limit: limit,
        };

        if (status.length) {
            params['status'] = status;
        }
        if (pickerFrom.length > 0 ) {
            params['dateFrom'] = pickerFrom;
        }
        if (pickerTo.length > 0 ) {
            params['dateTo'] = pickerTo;
        }


        const request = await toggleFetch( `/v1/player/activity/deposit?`+qs.stringify(params), {
            method: 'get',
        })

        const body = await request.json();

        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message =  body['message'];
        } else {
            response.response = body;
        }

        return response;

    } catch (error) {
        response.error =  true;
        response.message = error!.toString();

        return response
    }
}


export const getWithdrawal = async (offset:number, limit:number, status:string,  pickerFrom:string, pickerTo:string): Promise<ServiceResponse> => {
    let response:ServiceResponse = {
        error: false,
    };


    try {

        const params:any = {
            offset: offset,
            limit: limit,
        };

        if (status.length) {
            params['status'] = status;
        }
        if (pickerFrom.length > 0 ) {
            params['dataFrom'] = pickerFrom;
        }
        if (pickerTo.length > 0 ) {
            params['dataTo'] = pickerTo;
        }

        const request = await toggleFetch( `/v1/player/activity/withdraw?`+qs.stringify(params), {
            method: 'get',
        })

        const body = await request.json();

        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message =  body['message'];
        } else {
            response.response = body;
        }

        return response;

    } catch (error) {
        response.error =  true;
        response.message = error!.toString();

        return response
    }
}
export const getPromotion = async (offset:number, limit:number, pickerFrom:string,  pickerTo:string): Promise<ServiceResponse> => {
    let response:ServiceResponse = {
        error: false,
    };

    try {
        const params:any = {
            offset: offset,
            limit: limit,
        };


        if (pickerFrom.length > 0 ) {
            params['dataFrom'] = pickerFrom;
        }
        if (pickerTo.length > 0 ) {
            params['dataTo'] = pickerTo;
        }


        const request = await toggleFetch( `/v1/player/activity/promotion?`+qs.stringify(params), {
            method: 'get',
        })

        const body = await request.json();

        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message =  body['message'];
        } else {
            response.response = body;
        }

        return response;

    } catch (error) {
        response.error =  true;
        response.message = error!.toString();

        return response
    }
}

export const getSupport = async (): Promise<ServiceResponse> => {
    let response:ServiceResponse = {
        error: false,
    };

    try {
        const request = await toggleFetch( `/v1/site/contactus`, {
            method: 'get',
        })

        const body = await request.json();

        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message =  body['message'];
        } else {
            response.response = body;
        }

        return response;

    } catch (error) {
        response.error =  true;
        response.message = error!.toString();

        return response
    }
}

export const getOffers =  async (): Promise<ServiceResponse> => {

    let response:ServiceResponse = {
        error: false,
    };

    try {
        const request = await toggleFetch( '/v1/player/account/profile/promotions', {
            method: 'get',
        });

        const body = await request.json();
        
        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message =  body['message'];
        } else {
            response.response = body;
        }
        
        return response;
        

    } catch (error) {
        response.error =  true;
        response.message = error!.toString();

        return response
    }
}

export const getActiveBonuses =  async (): Promise<ServiceResponse> => {
    
    let response:ServiceResponse = {
        error: false,
    };
    
    try {
        const request = await toggleFetch( '/v1/player/account/profile/promotion/campaigns?active=true', {
            method: 'get',
        });
        
        const body = await request.json();
        
        if (Array.isArray(body)) {
            response.error = false;
            response.response =  body;
        } else {
            response.error = true;
        }
        
        return response;
        
        
    } catch (error) {
        response.error =  true;
        response.message = error!.toString();
        
        return response
    }
}

export const getFinishedBonuses =  async (): Promise<ServiceResponse> => {
    
    let response:ServiceResponse = {
        error: false,
    };
    
    try {
        const request = await toggleFetch( '/v1/player/account/profile/promotion/campaigns?active=false', {
            method: 'get',
        });
        
        const body = await request.json();
        
        if (Array.isArray(body)) {
            response.error = false;
            response.response =  body;
        } else {
            response.error = true;
        }
        
        return response;
        
        
    } catch (error) {
        response.error =  true;
        response.message = error!.toString();
        
        return response
    }
}


export const getUserActivity = async(path:string): Promise<ServiceResponse> => {
    let response:ServiceResponse = {
        error: false,
    };

    try {
        const request = await toggleFetch( path, {
            method: 'get',
        });

        const data = await request.json()

        if(data) {
            response.response = data
            response.error = false
            response.message = "ok"
        } else {
            response.error = true
            response.message = "not ok"
        }

        return response;
    } catch(e) {
        return response;
    }

}

export const refreshToken =  async (): Promise<ServiceResponse> => {

    let response:ServiceResponse = {
        error: false,
    };
    
    try {
        
        const request = await toggleFetch( '/v1/player/refreshToken', {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${getRefeshToken()}`,
            },
            credentials: "include"

        });

        const body = await request.json();

        response.response = body;
        return response;

    } catch (error) {
        response.error =  true;
        response.message = error!.toString();

        return response
    }
}

export function candyQuestServices() {
    
    const id = CMS.getCurrentQuest().quest_api_id
    
    const getOrPostStatus = async (token: string, method: "GET" | "POST") => {
        let response:ServiceResponse = {
            error: false,
        };

        try {

            //STAGE START
            // const request = await fetch('https://feature-quest-backend.stage.swphantom.com/feature/quest/opt-in?token='+token, {
            //     method: method
            // })
            //STAGE END

            const request = await fetch(`https://feature-quest-backend.swphantom.com/feature/quest/${id}/opt-in?token=`+token, {
               method: method,
                headers: {
                    'site_id': process.env.NEXT_PUBLIC_SITE_ID,
                }
            })

            const body = await request.json();

            if (body.hasOwnProperty('optIn') || body.hasOwnProperty('status')) {
                response.response = body;
            } else {
                response.error = true;
                response.message =  body['message'];
            }

            return response

        } catch(err) {
            response.error =  true;
            response.message = err!.toString();

            return response
        }
    }

    const getLeaderboard = async () => {
        let response:ServiceResponse = {
            error: false,
        };

        try {
            //STAGE START
            // const request = await fetch('https://feature-quest-backend.stage.swphantom.com/feature/quest/leaderboard', {
            //     method: 'GET'
            // })
            //STAGE END

            const request = await fetch(`https://feature-quest-backend.swphantom.com/feature/quest/${id}/leaderboard`, {
                method: 'GET',
                headers: {
                    'site_id': process.env.NEXT_PUBLIC_SITE_ID,
                }
            })

            const body = await request.json();

            if (body.hasOwnProperty('leaderboard')) {
                response.response = body;
                // console.log(body)
            } else {
                response.error = true;
                response.message =  "could not get leaderboard";
            }

            return response

        } catch(err) {
            response.error =  true;
            response.message = err!.toString();

            return response
        }
    }


    return {
        getOrPostStatus,
        getLeaderboard
    }
}

export const updateProfileAttributes = async (triggered: 'Triggered' | 'NotTriggered') => {

    let response:ServiceResponse = {
        error: false,
    };

    const updatedUser = {
        attribute: {
            attributeName: "WOFTriggered",
            value: triggered
        }
    }

    try {
        const request = await toggleFetch('/v1/player/account/profile', {
            method: 'post',
            body: JSON.stringify(updatedUser)
        })

        const body = await request.json();

        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message =  body['message'];
        } else {
            response.response = body;
        }

        return response;

    } catch (err) {
        response.error =  true;
        response.message = err!.toString();

        return response
    }
}

export const editProfile =  async (user:UserInterface): Promise<ServiceResponse> => {

    let response:ServiceResponse = {
        error: false,
    };

    try {
        const request = await toggleFetch( '/v1/player/account/profile', {
            method: 'post',
            body: JSON.stringify(user),
        });

        const body = await request.json();

        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message =  body['message'];
        } else {
            response.response = body;
        }

        return response;

    } catch (error) {
        response.error =  true;
        response.message = error!.toString();

        return response
    }
}

export const getLimitDeposit =  async (): Promise<ServiceResponse> => {

    let response:ServiceResponse = {
        error: false,
    };

    try {
        const request = await toggleFetch( '/v1/player/limit/deposit', {
            method: 'get',

        });

        const body = await request.json();

        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message =  body['message'];
        } else {
            response.response = body;
        }

        return response;

    } catch (error) {
        response.error =  true;
        response.message = error!.toString();

        return response
    }
}

export const changeLimitDeposit =  async (data:Array<any>): Promise<ServiceResponse> => {
    let response:ServiceResponse = {
        error: false,
    };

    try {
        const request = await toggleFetch( '/v1/player/limit/deposit', {
            method: 'post',
            body: JSON.stringify(data),
        });

        const body = await request.json();

        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message = body['message'];
        } else {
            response.response = body;
        }

        return response;

    } catch (error) {
        response.error =  true;
        response.message = error!.toString();

        return response
    }
}

export const addDocumentName  =  async (documentName:string, fields:any, document_type?:string): Promise<ServiceResponse> => {

    let response:ServiceResponse = {
        error: false,
    };

    try {
        const request = await toggleFetch( `/v1/player/verification/document?document_name=${documentName}&document_type=${document_type || 'other_document'}`, {
            method: 'post',
            body: fields,
        });

        const body = await request.json();

        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message =  body['message'];
        } else {
            response.response = body;
        }

        return response;

    } catch (error) {
        response.error =  true;
        response.message = error!.toString();

        return response
    }
}

export const getSupportUser = async (firstName:string, lastName:string, email:string, message:string, label: string, subject:string): Promise<ServiceResponse> => {
    let response:ServiceResponse = {
        error: false,
    };

    try {
        const request = await toggleFetch( `/v1/site/contactus`, {
            method: 'post',
            body: JSON.stringify({
                email, body: message,  subject: label, subjectId: parseInt(subject), firstName, lastName,
            }),
        })

        const body = await request.json();

        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message =  body['message'];
        } else {
            response.response = body;
        }

        return response;

    } catch (error) {
        response.error =  true;
        response.message = error!.toString();

        return response
    }
}

export const getPaymentMethods =  async (): Promise<ServiceResponse> => {
    let response:ServiceResponse = {
        error: false,
    };

    try {
        const request = await toggleFetch( '/v1/player/payment/methods', {
            method: 'get',
        });

        const body = await request.json();

        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message =  body['message'];
        } else {
            response.response = body;
        }

        return response;

    } catch (error) {
        response.error =  true;
        response.message = error!.toString();

        return response
    }
}

export const getBetBalance =  async (): Promise<ServiceResponse> => {
    let response:ServiceResponse = {
        error: false,
    };

    try {
        const request = await toggleFetch( '/v1/player/balance/pending', {
            method: 'get',
            // headers: {
            //     Authorization: `Bearer ${getAuthToken()}`,
            // }
        });

        const body = await request.json();

        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message =  body['message'];
        } else {
            response.response = body;
        }

        return response;

    } catch (error) {
        response.error =  true;
        response.message = error!.toString();

        return response
    }
}

export const getPaymentInfo =  async (methodType:string, method:string, amount:number, campaignId:string): Promise<ServiceResponse> => {
    let response:ServiceResponse = {
        error: false,
    };

    try {
        const request = await toggleFetch( '/v1/player/payment/paymentInfo', {
            method: 'POST',
            body: JSON.stringify(Payment.is_new ? {
                // methodType,
                method,
                amount,
                campaignId
            } : {
                methodType,
                method,
                amount,
                campaignId
            }),
        });

        const body = await request.json();

        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            if(body['message'].includes("is blocked")){
                response.message = "Din păcate, cererea dumneavoastră de depunere nu poate fi procesată acum. Vă rugăm să contactați serviciul de asistență pentru clienți."
            } else {
                response.message =  body['message'];
            }
            
        } else {
            response.response = body;
        }

        return response;

    } catch (error) {
        response.error =  true;
        response.message = error!.toString();

        return response
    }
}

export const getDocumentValidation = async (): Promise<ServiceResponse> => {

    let response:ServiceResponse = {
        error: false,
    };

    try {
        const request = await toggleFetch( '/v1/player/verification/document/validation', {
            method: 'get',
        });

        const body = await request.json();

        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message =  body['message'];
        } else {
            response.response = body;
        }

        return response;
    } catch (error) {
        response.error =  true;
        response.message = error!.toString();

        return response;
    }

}

export const getWithdrawMethods =  async (): Promise<ServiceResponse> => {
    let response:ServiceResponse = {
        error: false,
    };

    try {
        const request = await toggleFetch( '/v1/player/payment/withdraw', {
            method: 'get',
        });

        const body = await request.json();

        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message =  body['message'];
        } else {
            response.response = body;
        }

        return response;

    } catch (error) {
        response.error =  true;
        response.message = error!.toString();

        return response
    }
}

export const applyWithdraw =  async (method:string, amount:number, storedPayoutReference:string): Promise<ServiceResponse> => {
    let response:ServiceResponse = {
        error: false,
    };

    try {
        const request = await toggleFetch( '/v1/player/payment/withdraw', {
            method: 'post',
            body: JSON.stringify({
                method,
                amount,
                storedPayoutReference
            })
        });

        const body = await request.json();

        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message =  body['message'];
        } else {
            response.response = body;
        }

        return response;

    } catch (error) {
        response.error =  true;
        response.message = error!.toString();

        return response
    }
}

export const cancelWithdraw =  async (reference:string): Promise<ServiceResponse> => {
    let response:ServiceResponse = {
        error: false,
    };

    try {
        const request = await toggleFetch( '/v1/player/payment/cancelWithdraw?reference='+reference, {
            method: 'post',
            body: JSON.stringify({})
        });

        const body = await request.json();

        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message =  body['message'];
        } else {
            response.response = body;
        }

        return response;

    } catch (error) {
        response.error =  true;
        response.message = error!.toString();

        return response
    }
}

export const getFavoritesGames =  async (): Promise<ServiceResponse> => {
    let response:ServiceResponse = {
        error: false,
    };

    try {
        const request = await toggleFetch( '/v1/player/favorite', {
            method: 'get',
        });

        const body = await request.json();

        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message =  body['message'];
        } else {
            response.response = body;
        }

        return response;

    } catch (error) {
        response.error =  true;
        response.message = error!.toString();

        return response
    }
}

export const updateFavoriteGame =  async (gameId:string, add:boolean): Promise<ServiceResponse> => {
    let response:ServiceResponse = {
        error: false,
    };

    try {
        const request = await toggleFetch( `/v1/player/favoriteAction/${gameId}/${add ? 'Add' : 'Remove'}`, {
            method: 'get',
        });

        const body = await request.json();

        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message =  body['message'];
        } else {
            response.response = body;
        }

        return response;

    } catch (error) {
        response.error =  true;
        response.message = error!.toString();

        return response
    }
}

export const getRealityCheck =  async (): Promise<ServiceResponse> => {
    let response:ServiceResponse = {
        error: false,
    };

    try {
        const request = await toggleFetch( `/v1/player/limit/realityCheck`, {
            method: 'get',
        });

        const body = await request.json();

        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message =  body['message'];
        } else {
            response.response = body;
        }

        return response;

    } catch (error) {
        response.error =  true;
        response.message = error!.toString();

        return response
    }
}

export const resetRealityCheck =  async (): Promise<ServiceResponse> => {
    let response:ServiceResponse = {
        error: false,
    };

    try {
        const request = await toggleFetch( `/v1/player/limit/realityCheck/reset`, {
            method: 'post',
            body: JSON.stringify({})
        });

        const body = await request.json();

        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message =  body['message'];
        } else {
            response.response = body;
        }

        return response;

    } catch (error) {
        response.error =  true;
        response.message = error!.toString();

        return response
    }
}

export const pauseAccount =  async (password:string, days:number): Promise<ServiceResponse> => {
    let response:ServiceResponse = {
        error: false,
    };

    try {
        const request = await toggleFetch( `/v1/player/coolOff`, {
            method: 'post',
            body: JSON.stringify({password, days})
        });

        const body = await request.json();

        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message =  body['message'];
        } else {
            response.response = body;
        }

        return response;

    } catch (error) {
        response.error =  true;
        response.message = error!.toString();

        return response
    }
}

export const excludeAccount =  async (password:string, closeExpireDate:string): Promise<ServiceResponse> => {
    let response:ServiceResponse = {
        error: false,
    };

    try {
        const request = await toggleFetch( `/v1/player/selfExclusion`, {
            method: 'post',
            body: JSON.stringify({password, closeExpireDate})
        });

        const body = await request.json();

        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message =  body['message'];
        } else {
            response.response = body;
        }

        return response;

    } catch (error) {
        response.error =  true;
        response.message = error!.toString();

        return response
    }
}

export const closeAccount =  async (password:string, reasonId:number): Promise<ServiceResponse> => {
    let response:ServiceResponse = {
        error: false,
    };

    try {
        const request = await toggleFetch( `/v1/player/close`, {
            method: 'post',
            body: JSON.stringify({password, reasonId})
        });

        const body = await request.json();

        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message =  body['message'];
        } else {
            response.response = body;
        }

        return response;

    } catch (error) {
        response.error =  true;
        response.message = error!.toString();

        return response
    }
}

export const closeAccountReasons =  async (): Promise<ServiceResponse> => {
    let response:ServiceResponse = {
        error: false,
    };

    try {
        const request = await toggleFetch( `/v1/player/close-account-reasons`, {
            method: 'get'
        });

        const body = await request.json();

        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message =  body['message'];
        } else {
            response.response = body;
        }

        return response;

    } catch (error) {
        response.error =  true;
        response.message = error!.toString();

        return response
    }
}

export const forgotPassword =  async (birthDate:any, email:string): Promise<ServiceResponse> => {
    let response:ServiceResponse = {
        error: false,
    };

    try {
        const request = await toggleFetch( `/v1/player/account/profile/forgotPassword`, {
            method: 'post',
            body: JSON.stringify({
                birthDate, email
            })
        });

        const body = await request.json();


        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message =  body['message'];
        } else {
            response.response = body;
        }

        return response;

    } catch (error) {
        response.error =  true;
        response.message = error!.toString();

        return response
    }
}

export const resetPassword =  async (newPassword:any, confirmPassword:string, activationCode:any): Promise<ServiceResponse> => {
    let response:ServiceResponse = {
        error: false,
    };

    try {
        const request = await toggleFetch( `/v1/player/account/profile/forgotPassword/reset`, {
            method: 'post',
            body: JSON.stringify({
                newPassword, confirmPassword, activationCode
            })
        });

        const body = await request.json();

        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message =  body['message'];
        } else {
            response.response = body;
        }

        return response;

    } catch (error) {
        response.error =  true;
        response.message = error!.toString();

        return response
    }
}

export const validateUserData =  async ({email = '', phoneNumber = '', cnp = ''}): Promise<ServiceResponse> => {
    let response:ServiceResponse = {
        error: false,
    };

    const query:any = {};

    if (email.length) {
        query['email'] = email;
    }

    if (phoneNumber.length) {
        query['phonenumber'] = phoneNumber;
    }

    if (cnp.length) {
        query['cnp'] = cnp;
    }

    const trackParams = cashPotTrackQueryParams(true);

    try {
        const request = await toggleFetch( `/v1/player/validate?${qs.stringify(query) + trackParams}`, {method: 'get'});

        const body = await request.json();

        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message =  body['message'];
        } else {
            response.response = body;
        }

        return response;

    } catch (error) {
        response.error =  true;
        response.message = error!.toString();

        return response
    }
}

export const confirmEmailVerification = async (code:string) => {
    let response:ServiceResponse = {
        error: false,
    };

    try {
        const request = await toggleFetch( `/v1/player/verification/account/email`, {
            method: 'post',
            body: JSON.stringify({
                code
            })
        });

        const body = await request.json();

        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message =  body['message'];
        } else {
            response.response = body;
        }

        return response;

    } catch (error) {
        response.error =  true;
        response.message = error!.toString();

        return response
    }
}

export const requestEmailVerification = async () => {
    let response:ServiceResponse = {
        error: false,
    };

    const trackParams = cashPotTrackQueryParams(false);

    try {
        const request = await toggleFetch( `/v1/player/verification/account/email${trackParams}`, {
            method: 'PATCH',
            body: JSON.stringify({}),
        });

        const body = await request.json();

        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message =  body['message'];
        } else {
            response.response = body;
        }

        return response;

    } catch (error) {
        response.error =  true;
        response.message = error!.toString();

        return response
    }
}

export const confirmPhoneVerification = async (code:string) => {
    let response:ServiceResponse = {
        error: false,
    };

    const trackParams = cashPotTrackQueryParams(false);

    try {
        const request = await toggleFetch( `/v1/player/verification/account/phone${trackParams}`, {
            method: 'post',
            body: JSON.stringify({
                code
            })
        });

        const body = await request.json();

        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message =  body['message'];
        } else {
            response.response = body;
        }

        return response;

    } catch (error) {
        response.error =  true;
        response.message = error!.toString();

        return response
    }
}

export const requestPhoneVerification = async () => {
    let response:ServiceResponse = {
        error: false,
    };

    const trackParams = cashPotTrackQueryParams(false);

    try {
        const request = await toggleFetch( `/v1/player/verification/account/phone${trackParams}`, {
            method: 'PATCH',
            body: JSON.stringify({}),
        });

        const body = await request.json();

        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message =  body['message'];
        } else {
            response.response = body;
        }

        return response;

    } catch (error) {
        response.error =  true;
        response.message = error!.toString();

        return response
    }
}

export const getVerificationData = async (method:string) => {
    let response:ServiceResponse = {
        error: false,
    };

    try {
        const request = await toggleFetch( `/v1/player/verification/account/`+method, {
            method: 'GET',
        });

        const body = await request.json();

        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message =  body['message'];
        } else {
            response.response = body;
        }

        return response;

    } catch (error) {
        response.error =  true;
        response.message = error!.toString();

        return response
    }
}

export const initRegistration = async () => {
    let response:ServiceResponse = {
        error: false,
    };




    const trackParams = cashPotTrackQueryParams(false);

    try {
        const request = await toggleFetch( `/v1/player/initRegister`+trackParams, {
            method: 'GET',
        });

        const body = await request.json();

        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message =  body['message'];
        } else {
            response.response = body;
        }

        return response;

    } catch (error) {
        response.error =  true;
        response.message = error!.toString();

        return response
    }
}

export  const getLastGames = async() => {
    let response:ServiceResponse = {
        error: false,
    };

    try {
        const request = await toggleFetch( `/v1/player/category?categories=lastPlayed`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${getAuthToken()}`,
            }
        });

        const body = await request.json();

        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message =  body['message'];
        } else {
            response.response = body;
        }

        return response;

    } catch (error) {
        response.error =  true;
        response.message = error!.toString();

        return response
    }
}

export const pending_claims = async() => {

    let response:ServiceResponse = {
        error: false,
    };
    
    try {
        const request = await cashpotFetch( `/v1/player/account/profile/promotion/campaigns/pending-claim`, {
            method: 'GET',
        });

        const body = await request.json();
        
        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message =  body['message'];
        } else {
            response.response = body;
        }
        
        return response;
        
        
    } catch (error) {
        response.error =  true;
        response.message = error!.toString();
        
        return response
    }
}

export const campaign_claim = async (id:string, index:number) => {
    
    let response:ServiceResponse = {
        error: false,
    };
    
    let data = {
        campaignId: id,
        promotionPackageIndex: index
    }
    
    try {
        
        const request = await cashpotFetch( `/v1/player/account/profile/promotion/campaign/claim`, {
            method: 'POST',
            body: JSON.stringify(data)
        });
        
        const body = await request.json();
        
        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message =  body['message'];
        } else {
            response.response = body;
        }
        
        return response;
        
        
    } catch (error) {
        response.error =  true;
        response.message = error!.toString();
        
        return response
    }

}


