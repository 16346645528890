export type DeviceType = "App Store" | "Google Play" | "AppGallery" | "Android"

export enum bonus_types  {
    Bonus = "bonus",
    FreeBets = "freeBets",
    TimedFreeSpins = "timedFreeSpins",
    FreeSpins = "freeSpins",
    FreeBonus = "freeBonus",
    TextPrize = "textPrize",
    CashBonus = "cashBonus",
    Unknown = "unknown"
}

export enum bonus_status  {
    Active = "active",
    Expired = "expired",
    Finished = "finished",
    All = "all",
    Unknown = "unknown"
}

export enum fr_bet_type  {
    FreeBetsClassic = "freeBetsClassic",
    Unknown = "unknown"
}