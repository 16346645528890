import {jackpot_types} from "@/AbsoluteComponents/interfaces/types";
import {updateAllJackpots} from "@/AbsoluteComponents/store/common/actions";
import AllJpInterface from "@/AbsoluteComponents/interfaces/all_jp_interface";
import {useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";

const JPInit = () => {
    const dispatch = useDispatch()
    const all_jackpots:AllJpInterface | undefined = useSelector((state:any) => state.common.all_jackpots);
    
    const effect_ran = useRef(false)
    const interval = useRef<any>(null)
    
    const fetchInitials = async () => {
        
        if (typeof all_jackpots === "object" && Object.keys(all_jackpots).length) {
            for (const k in all_jackpots) {
                if (all_jackpots[k as jackpot_types] !== undefined) {
                    return;
                }
            }
        }
        
        const endpoint = process.env.NEXT_PUBLIC_CUSTOM_JACKPOTS_ENDPOINT || '/api/dynamic/jackpots.json'
        
        try {
            const response = await fetch(endpoint, {
                method: 'GET',
            });
            
            if (response.ok) {
                const data = await response.json();
                // dispatch(updateAllJackpots(initJpFactory(data) as AllJpInterface))
                dispatch(updateAllJackpots(data as AllJpInterface))
                
            } else {
                console.error('Failed to fetch jackpots data:', response.status);
            }
        } catch (error) {
            console.error('Error fetching jackpots data:', error);
        }
    }
    
    useEffect(() => {
        fetchInitials()
        
        if(!effect_ran.current) {
            interval.current = setInterval(fetchInitials, 10000)
        }
        
        return () => {
            effect_ran.current = true
            clearInterval(interval.current)
        }
        
    }, []);
    
    return <></>
}

export default JPInit