import CMS from "@/AbsoluteComponents/utils/cms";
import JPInit from "@/AbsoluteComponents/jp_init";
import AllJpInterface from "@/AbsoluteComponents/interfaces/all_jp_interface";
import {useSelector} from "react-redux";
import JackpotInterface from "@/AbsoluteComponents/interfaces/jp_interface";
import {fever_types, jackpot_types} from "@/AbsoluteComponents/interfaces/types";
import st from '@/template/styles/OnPageJP.module.scss';
import clsx from "clsx";
import React, {useEffect, useRef, useState} from "react";
import LazyImage from "@/AbsoluteComponents/ui/LazyImage";
import Image from "next/image";
import Skeleton from "@mui/material/Skeleton";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Pagination} from "swiper";
import PaginationArrow from "@/AbsoluteComponents/ui/paginationArrow";
import JackpotCardDynamic from "@/template/cms-views/jpFever/jackpot_card_dynamic";
import RoundedLeveling from "@/template/ui/RoundedLeveling";
import JackpotCounterDunamic from "@/template/cms-views/jpFever/jackpot_counter_dynamic";
import styles from "@/template/styles/JpFever.module.scss";
// @ts-ignore
import numberFormatter from 'number-formatter';
import moment from "moment";
import strings from "@/AbsoluteComponents/utils/strings";
import GameInterface from "@/AbsoluteComponents/interfaces/game";
import Game from "@/AbsoluteComponents/utils/game";
import BurningBox from "@/template/small_ui/BurningBox";

interface Props {
    json: any
}

const OnPageJP:React.FC<Props> = ({json}) => {
    const [swiper, setSwiper] = useState<any>();
    const data  = CMS.getAllFields(json)
    const {jp_key} = data
    const decorations = useRef<any>(null)
    const all_jackpots:AllJpInterface | undefined = useSelector((state:any) => state.common.all_jackpots);
    const topValsStyle = {maxHeight: "22px", width: `fit-content`}
    let skeletonLength = (jp_key as jackpot_types ) !== "hotLuck" ?  4 : 3
    let skeletonArray: Array<any> = []
    const allGames:Array<GameInterface> = Game.getAll();
    const gamesFound:Array<GameInterface> =  allGames.filter((g:any) => g.categories.includes(data.category_id ? data.category_id : data.category_id_));
    // console.log(data)
    for (let i = 0; i < skeletonLength; i++) {
        skeletonArray.push(i)
    }
    
    const get_local = () => {
        
        let res:JackpotInterface | undefined = undefined
        
        if(typeof jp_key === "string" && jp_key.length && all_jackpots) {
            res = all_jackpots[jp_key as jackpot_types]
        }
        
        return res
    }
    
    const local_jp = get_local()
    // console.log("local_jp", local_jp)
    const is_local_jp = typeof local_jp !== "undefined"
    
    
    useEffect(() => {
        if(is_local_jp) {
            
            // if(decorations.current) decorations.current.style.opacity = "1"
        }
    }, [local_jp])
    
    
    return (
        <>
            <JPInit />
            
            <section className={clsx(st.main, (is_local_jp && local_jp.jackpot_name) && st[local_jp.jackpot_name])}>
                
                <div className={st.background}>
                    <div
                        ref={decorations}
                        className={st.decorations}
                        style={{
                            backgroundImage: `url('/default/images/JackpotsLive/backgrounds/${jp_key}.svg')`
                        }}
                    />
                
                </div>
                
                <div className={st.content}>
                    
                    <div className={st.header}>
                        {Array.isArray(gamesFound) ? (
                            <span className={st.games}>
                                {gamesFound.length === 1 ? gamesFound.length + " joc" : gamesFound.length + " jocuri"}
                            </span>
                        ) : null}
                        
                        <div className={st.img_container}>
                            
                            <Image
                                src={`/default/images/JackpotsLive/logos/${jp_key}.webp`}
                                alt={"jackpot"}
                                height={120}
                                width={312}
                                layout={"responsive"}
                                className={st.jp_logo}
                            />
                        
                        </div>
                        
                        <div className={st.divider}>
                        
                        </div>
                        
                        <div className={st.end}>
                            {is_local_jp ? (
                                <Image
                                    src={`/default/images/JackpotsLive/providers/${local_jp?.provider}.webp`}
                                    alt={"jp provider"}
                                    width={123}
                                    height={56}
                                />
                            ) : (
                                <Skeleton
                                    sx={{
                                        width: "123px",
                                        height: "56px",
                                    }}
                                    animation="wave"
                                    variant="rectangular"
                                />
                            )}
                        </div>
                    
                    
                    </div>
                    
                    <div className={st.body}>
                        <Swiper
                            className={clsx(st.slider)}
                            spaceBetween={32}
                            slidesPerView={"auto"}
                            onSwiper={(sw: any) => {
                                setSwiper(sw)
                            }}
                            breakpoints={{
                                1400: {
                                    spaceBetween: 20
                                },
                                0: {
                                    spaceBetween: 20,
                                },
                            }}
                        >
                            
                            {is_local_jp ? (
                                <>
                                    {Array.isArray(local_jp?.levels) ? local_jp?.levels.map((x, i) => {
                                        
                                        return (
                                            <SwiperSlide
                                                key={'jackpot_level' + i}
                                                className={clsx(st.slide)}
                                            >
                                                <div className={st.jp_level}>
                                                    
                                                    <LazyImage
                                                        src={`/default/images/JackpotsLive/pure_cards/${x.fever}.svg`}
                                                        alt={"level"}
                                                        className={st.image}
                                                        // width={292}
                                                        // height={340}
                                                    />
                                                    
                                                    <div className={st.jv_info}>
                                                        
                                                        <div className={st.top}>
                                                            <RoundedLeveling
                                                                value={x.percentage}
                                                                fever={x.fever}
                                                                className={st.leveling}
                                                            />
                                                            
                                                            <strong>
                                                                {x.lv_name}
                                                            </strong>
                                                        </div>
                                                        
                                                        <BurningBox
                                                            main_box_class={st.center_container}
                                                            dark_box_class={st.center}
                                                            glowing={x.percentage > 95}
                                                        >
                                                            <div className={st.val}>
                                                                
                                                                <JackpotCounterDunamic
                                                                    id={"counter_top"}
                                                                    textWidth={12}
                                                                    textHeight={20}
                                                                    style={topValsStyle}
                                                                    className={clsx(styles.valueContainer, st.valueContainer)}
                                                                    jackpot={local_jp}
                                                                    level={x}
                                                                    value={x.value && x.value}
                                                                    on_jp_page
                                                                    static_counter={x.hasOwnProperty("static") ? x.static : false}
                                                                />
                                                                
                                                                <span className={st.ron}>
                                                                    RON
                                                                </span>
                                                            
                                                            </div>
                                                            
                                                            
                                                            <div
                                                                className={clsx(st.badge, st[x.fever])}
                                                            >
                                                                {strings[x.fever]}
                                                            </div>
                                                        </BurningBox>
                                                        
                                                        <div className={st.numbers}>
                                                            
                                                            <div className={clsx(st.latest, st.nm)}>
                                                                 <span>
                                                                    Ultimul câștig
                                                                </span>
                                                                
                                                                <strong>
                                                                    {x.last_win ? numberFormatter("#.##0,####", x.last_win) + " RON" : "N/A"}
                                                                </strong>
                                                            </div>
                                                            
                                                            <div className={clsx(st.latest_date, st.nm)}>
                                                                 <span>
                                                                    Data câștigului
                                                                </span>
                                                                
                                                                <strong>
                                                                    {(typeof x.latest_win_date === 'string' && x.latest_win_date.length) ? moment(x.latest_win_date).format('DD/MM/YYYY') : "N/A"}
                                                                </strong>
                                                            </div>
                                                        
                                                        
                                                        </div>
                                                    
                                                    </div>
                                                
                                                </div>
                                            </SwiperSlide>
                                        )
                                    }) : null}
                                </>
                            ) : (
                                <>
                                    {skeletonArray.map((x, i) => {
                                        return (
                                            <SwiperSlide
                                                key={'jackpot_skeleton' + i}
                                                className={clsx(st.slide)}
                                            >
                                                <div className={clsx(st.jp_level, st.skeleton)}>
                                                    <Skeleton
                                                        sx={{
                                                            width: "100%",
                                                            height: "100%",
                                                        }}
                                                        animation="wave"
                                                        variant="rectangular"
                                                    />

                                                </div>
                                            </SwiperSlide>
                                        )
                                    })}
                                </>
                            )}
                        
                        </Swiper>
                    </div>
                
                </div>
            
            </section>
        </>
    )
}



export default OnPageJP