import React, { ReactNode, useEffect, useRef } from "react";
import st from "@/template/styles/Ui.module.scss";
import clsx from "clsx";

interface Burning {
    children: ReactNode;
    main_box_class?: string;
    dark_box_class?: string;
    glow_class?: string;
    glowing?: boolean;
}

const BurningBox: React.FC<Burning> = (props) => {
    const { children, main_box_class, dark_box_class, glowing, glow_class } = props;
    const burningRef = useRef<HTMLDivElement>(null);
    
    useEffect(() => {
        const isIOSDevice = /iPhone|iPad|iPod/i.test(navigator.userAgent);
        
        if (burningRef.current && !isIOSDevice) {
            burningRef.current.classList.add(st.non_ios);
        }
    }, []);
    
    return (
        <div
            ref={burningRef}
            className={clsx(st.burning, main_box_class || "")}
        >
            {glowing && <span className={clsx(st.glow_bg, glow_class || "")}></span>}
            
            <div className={clsx(st.bg_dark, dark_box_class || "")}>
                {children}
            </div>
        </div>
    );
};

export default BurningBox;
