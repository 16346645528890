import {FC, useState, useRef, useEffect} from 'react';
import {openSignupModal} from "store/modals/actions";
import {updateSnackBar} from "store/common/actions";
import strings from "utils/strings";
import Button from "@/template/small_ui/button";
import Modal from 'react-modal';
import {useDispatch, useSelector} from 'react-redux';
import styles from '@/template/styles/signup.module.scss';
import clsx from 'clsx';
import StepOne from "./components/StepOne";
import StepTwo from "./components/StepTwo";
import {initRegistration, signUpService, validateUserData} from "services/account";
import {setAuthToken} from "utils/cookies";
import {updateVerificationStatus} from "store/account/actions";
import useAuth from "hooks/auth";
import CMS from "utils/cms";
import {validateEmail} from 'utils/constants';
import ModalLayout from "@/template/ui/modal_layout";


const SignUp:FC = () => {

    const dispatch = useDispatch();
    const auth = useAuth();
    const contentReg = useRef<any>(null);

    const open:boolean = useSelector((state:any) => state.modals.openSignup);
    const [sending, setSending] = useState<boolean>(false);
    const [step, setStep] = useState<number>(1);
    const [formData, setFormData] = useState<any>({
        loading: false,
        errors: {},
    });

    
    
    const valuesRef = useRef<any>({
        email: '',
        password: '',
        promoCode: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        cnp: '',
        address: '',
        city: '',
        userName: '',
        ageChecked: false,
        policyChecked: false,
        receiveSms: true,
        receiveEmail: true,
        nickName: ''
    });

    const responseValidate = (validations:Array<any>) => {
        const errors:any = {};

        validations.map((item:any) => {
            if (item.validateDetailsType === 'email' && !item.available) {
                if (item?.error?.exists) {
                    errors['email'] = 'E-mail deja utilizat';
                }

                if (item?.error?.invalid) {
                    errors['email'] = 'E-mail invalid';
                }
            }

            if (item.validateDetailsType === "phoneNumber" && !item.available) {
                if (item?.error?.exists) {
                    errors['phoneNumber'] = 'Numar de telefon existent';
                }

                if (item?.error?.invalid) {
                    errors['phoneNumber'] = 'Numar de telefon invalid';
                }
            }

            if (item.validateDetailsType === "personalId" && !item.available) {
                if (item?.error?.exists) {
                    errors['cnp'] = 'Cod CNP existent';
                }

                if (item?.error?.invalid) {
                    if (item?.error?.error === 'Error: Under age') {
                        errors['cnp'] = 'Pentru înregistrare, vârsta minimă este de 18 ani, împliniți';
                    } else {
                        errors['cnp'] = 'Codul CNP este greșit.';
                    }
                }
            }
        });

        return errors;
    }

    const validateStepOne = async (e:any):Promise<boolean> => {
        const form = new FormData(e.target);
        let errors:any = {};

        const email = form.get('email')?.toString() || '';
        const phoneNumber = form.get('phone_number')?.toString() || '';
        const phone = form.get('phone')?.toString() || '';
        const terms = form.get('terms')?.toString() || '';
        const password = form.get('password')?.toString() || '';
        const password_rules = form.get('password_rules') || 'false';
        const password_pending_rules = form.get('password_pending_rules') || '';

        if (terms !== 'agree') {
            errors['terms'] = 'Trebuie să confirmi pentru a continua';
        }

        if (!email.length) {
            errors['email'] = 'Acest câmp trebuie completat';
        } else if (!validateEmail(email)) {
            errors['email'] = 'E-mail invalid';
        }

        if (!phone.length) {
            errors['phoneNumber'] = 'Acest câmp trebuie completat';
        }

        if (!password.length) {
            errors['password'] = 'Acest câmp trebuie completat';
        } else if (password_rules === 'false') {
            errors['password'] = 'Parola trebuie să conțină ' + password_pending_rules;
        }

        if (Object.keys(errors).length) {
            setFormData((data:any) => ({
                ...data,
                loading: false,
                errors: errors,
            }));

            return false;
        }

        setFormData((data:any) => ({
            ...data,
            loading: true,
            errors: {},
        }));

        const validation = await validateUserData({
            email: email.toString(),
            phoneNumber: phoneNumber.toString(),
        });

        if (Array.isArray(validation.response)) {
            const validationResponse = responseValidate(validation.response);

            if (Object.keys(validationResponse).length) {
                errors = {...errors, ...validationResponse};
            }
        }

        if (Object.keys(errors).length) {
            setFormData((data:any) => ({
                loading: false,
                errors: errors,
            }));
        } else {
            valuesRef.current = {
                ...valuesRef.current,
                email: email,
                phoneNumber: phoneNumber,
                password: password,
                policyChecked: terms === 'agree'
            };

            setFormData((data:any) => ({
                loading: false,
                errors: {},
            }));

            return true;
        }

        return false
    }

    const validateStepTwo = async (e:any):Promise<boolean> => {
        const form = new FormData(e.target);
        let errors:any = {};

        const userName = form.get('reg_nickname')?.toString() || '';
        const promoCode = form.get('promoCode')?.toString() || '';
        const firstName = form.get('firstName')?.toString() || '';
        const lastName = form.get('lastName')?.toString() || '';
        const cnp = form.get('cnp')?.toString() || '';
        const address = form.get('address')?.toString() || '';
        const city = form.get('city')?.toString() || '';
        // const nickName = form.get('nickName')?.toString() || '';
        const receiveSms:boolean = form.get('receiveSMS')?.toString() === 'agree';
        const receiveEmail:boolean = form.get('receiveEmail')?.toString() === 'agree';

        if (!userName.length) errors['reg_nickname'] = 'Acest câmp trebuie completat';
        if (!firstName.length) errors['firstName'] = 'Acest câmp trebuie completat';
        if (!lastName.length) errors['lastName'] = 'Acest câmp trebuie completat';
        if (!cnp.length) errors['cnp'] = 'Acest câmp trebuie completat';
        if (!address.length) errors['address'] = 'Acest câmp trebuie completat';
        if (!city.length) errors['city'] = 'Acest câmp trebuie completat';
        // if (!nickName.length) errors['nickName'] = 'Acest câmp trebuie completat';

        if (Object.keys(errors).length) {
            setFormData((data:any) => ({
                ...data,
                loading: false,
                errors: errors,
            }));

            return false;
        }

        setFormData((data:any) => ({
            ...data,
            loading: true,
            errors: {},
        }));

        const validation = await validateUserData({
            cnp: cnp,
        });

        if (Array.isArray(validation.response)) {
            const validationResponse = responseValidate(validation.response);

            if (Object.keys(validationResponse).length) {
                errors = {...errors, ...validationResponse};
            }
        }

        if (Object.keys(errors).length) {
            setFormData((data:any) => ({
                loading: false,
                errors: errors,
            }));
        } else {
            valuesRef.current = {
                ...valuesRef.current,
                promoCode: promoCode,
                firstName: firstName,
                lastName: lastName,
                cnp: cnp,
                address: address,
                city: city,
                receiveSms: receiveSms,
                receiveEmail: receiveEmail,
                userName: userName,
                // nickName: nickName
            };

            setFormData((data:any) => ({
                loading: false,
                errors: {},
            }));

            return true;
        }

        return false
    }

    let launcher = typeof window !== 'undefined' ? document.querySelector('#launcher') :  null;

    const onSubmit = async (e:any) => {
        e.preventDefault();
        
        if (step === 1) {
            const stepOneValid:boolean = await validateStepOne(e);

            if (stepOneValid) setStep(step + 1);
        }

        if (step === 2) {
            const stepTwoValid:boolean = await validateStepTwo(e);

            if (stepTwoValid) {
                setTimeout(async () => {
                    setSending(true);
                    
                    const registration = await signUpService(valuesRef.current);

                    if (registration.error) {
                        if (Array.isArray(registration.details)) {
                            registration.details.map((msg) => {
                                dispatch(updateSnackBar(strings[msg] || msg, 'error'));
                            });
                        } else {

                            let err_message:string = ''

                            if(
                                registration.message?.includes("Username") &&
                                registration.message?.includes("is already used")
                            ) {
                                err_message = strings['username_used']
                            } else {
                                err_message = registration?.message || ""
                            }

                            dispatch(updateSnackBar(err_message, 'error'));

                        }

                        setSending(false);
                    } else if (typeof registration.response === "object" && typeof registration.response['accessToken'] === 'string') {
                        setAuthToken(registration.response['accessToken']);

                        if ( Array.isArray(registration.response['verificationStatus']) ) {
                            dispatch(updateVerificationStatus(auth.verificationStatusFactory(registration.response['verificationStatus'])));
                        }

                        window.location.href = '/?modal=verification';

                        CMS.fbTrackRegister(valuesRef.current);
                        CMS.gtagTrackRegister(valuesRef.current);

                    } else {
                        setSending(false);
                        dispatch(updateSnackBar(strings['unknown_error'], 'error'));
                    }
                }, 0)
            }
        }
    };

    useEffect(() => {
        let launcher = typeof window !== 'undefined' ? document.querySelector('#launcher') :  null;
        launcher?.classList.add('hideLauncher');

        contentReg.current?.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }, [step]);

    const hideZendesk = () => {
        launcher?.classList.remove('hideLauncher');
    }

    return (
        <Modal
            ariaHideApp={false}
            isOpen={open}
            onAfterOpen={() => {
                initRegistration();
                // hideZendesk();
            }}
            onRequestClose={() => {
                dispatch(openSignupModal(false));
                const root =  document.getElementsByTagName('html')[0];
                root.removeAttribute('style');
            }
            
        }
            contentLabel="Example Modal"
            className={clsx(styles.modal, 'theme_background_1')}
        >

            <ModalLayout
                title={strings['create_account']}
                // withPadding
                onClose={() => {
                dispatch(openSignupModal(false))
                const root =  document.getElementsByTagName('html')[0];
                root.removeAttribute('style');
            }}>

                <>
                    <div className={clsx("ReactModal__Inner__Header", "hide_for_spin")}>
                        <h2
                            className="ReactModal__Title"
                        >
                            {strings["create_account"]}
                        </h2>
                    </div>

                    <div
                        style={{marginBottom: '12px'}}
                        className="ReactModal__Inner__Header"
                    >
                        <ul className={styles.steps}>
                            <li className={clsx(styles.steps__item  , step > 0 && styles.active)}></li>

                            <li className={clsx(styles.steps__divider, 'theme_primitive_bg', step > 1 && styles.active)}></li>
                            <li className={clsx(styles.steps__item, 'theme_sign_up_step' , step > 1 && styles.active)}></li>

                            <li className={clsx(styles.steps__divider, 'theme_primitive_bg', step > 2 && styles.active)}></li>
                            <li className={clsx(styles.steps__item, 'theme_sign_up_step', step > 2 && styles.active)}></li>
                        </ul>
                    </div>

                    <div className="ReactModal__Inner__Content" ref={contentReg}>

                        <form onSubmit={onSubmit} autoComplete={'off'} autoSave={'off'}>
                            {step === 1 &&
                                <div>
                                    <StepOne errors={formData.errors}/>
                                </div>
                            }
                            
                            {step === 2 &&
                                <div>
                                    <input
                                        readOnly
                                        style={{
                                            zIndex: 10,
                                            position: "absolute",
                                            pointerEvents: "none",
                                            opacity: "0"
                                        }}
                                        value={valuesRef.current.email ? valuesRef.current.email : ""}
                                        type={"email"}
                                        name={"username"}
                                    />
                                    
                                    <input
                                        readOnly
                                        style={{
                                            zIndex: 10,
                                            position: "absolute",
                                            pointerEvents: "none",
                                            opacity: "0"
                                        }}
                                        value={valuesRef.current.password ? valuesRef.current.password : ""}
                                        type={"password"}
                                        name={"password"}
                                    />
                                    
                                    <StepTwo
                                        errors={formData.errors}
                                    />
                                </div>
                            }
                            
                            <div className={'signUp_buttons_wrapper'}>
                                
                                {step > 1 &&
                                    <Button
                                        outlined
                                        loading={(sending)}
                                        text={'Înapoi'}
                                        onClick={() => setStep(step - 1)}
                                        className={styles.btns}
                                    />
                                }
                                
                                <Button
                                    text={step === 1 ? `${strings['agree']}` : `${strings['next_step']}`}
                                    loading={(formData.loading || sending)}
                                    type={'submit'}
                                    className={styles.btns}
                                />
                            
                            </div>
                        
                        </form>
                    
                    </div>
                </>
            
            </ModalLayout>
        
        </Modal>
    )
};

export default SignUp;