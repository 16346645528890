export type DeviceType = "App Store" | "Google Play" | "AppGallery" | "Android"

export enum bonus_types  {
    Bonus = "bonus",
    FreeBets = "freeBets",
    TimedFreeSpins = "timedFreeSpins",
    FreeSpins = "freeSpins",
    FreeBonus = "freeBonus",
    TextPrize = "textPrize",
    CashBonus = "cashBonus",
    Unknown = "unknown"
}

export enum bonus_status  {
    Active = "active",
    Expired = "expired",
    Finished = "finished",
    All = "all",
    Unknown = "unknown"
}

export enum fr_bet_type  {
    FreeBetsClassic = "freeBetsClassic",
    Unknown = "unknown"
}

export enum jackpot_types {
    BellLink = "bellLink",
    Clover = "clover",
    Egypt = "egypt",
    HighCash = "highCash",
    HotLuck = "hotLuck",
    JackpotCards = "jackpotCards",
    PlayJackpot = "playJackpot",
    RegalJackpot = "regalJackpot"
}

export enum jackpot_types_old {
    BellLink = "Bell Link API",
    Clover = "Clover Chance API",
    Egypt = "Egypt Quest API",
    HighCash = "High Cash API",
    HotLuck = "Hot Luck API",
    JackpotCards = "Jackpot Cards API",
    PlayJackpot = "Play Jackpot API",
    RegalJackpot = "Regal Jackpot API"
}

export enum fever_types {
    CoolStart = "cool_start",
    WarmUp = "warm_up",
    BlazingHot = "blazing_hot",
    OnFire = "on_fire",
    Unknown = "unknown"
}

