import React from 'react'
import styles from '@/template/styles/JpFever.module.scss';
import clsx from 'clsx'
// @ts-ignore
import numberFormatter from 'number-formatter';
import JackpotCounterDunamic from "./jackpot_counter_dynamic";
import {useRouter} from 'next/router';
import Image from "next/image";
import {useSelector} from "react-redux";
import PlayLight from 'themes/default/svg/playLight';
import LazyImage from "@/AbsoluteComponents/ui/LazyImage";
import RoundedLeveling from "@/template/ui/RoundedLeveling";
import {fever_types} from "@/AbsoluteComponents/interfaces/types";
import Button from "@/template/small_ui/button";
import strings from "@/AbsoluteComponents/utils/strings";
import BurningBox from "@/template/small_ui/BurningBox";



interface Props {
    jackpot: any
    api_id: string
    url: string
}

const JackpotCard: React.FC<Props> = ({jackpot, url}) => {
    const router = useRouter()
    const isMobile:boolean = useSelector((state:any) => state.common.isMobile);
    
    const navigateToJPPage = () => {router.push('/' + url)}
    const topValsStyle = {maxHeight: "26px", width: `fit-content`}
    
    return (
        <div
            onClick={isMobile ? navigateToJPPage : () => {
            }}
            className={clsx(styles.JackpotCard, 'jackpot-card', styles.needFix, jackpot.jackpot_name)}
        >
            <LazyImage
                src={`/default/images/JackpotsLive/${jackpot.levels[0].fever}/${jackpot.jackpot_name}.webp`}
                alt={jackpot.jackpot_name}
                className={styles.JackpotCard__bg_img}
            />
            
            <LazyImage
                src={`/default/images/JackpotsLive/${jackpot.levels[0].fever}/${jackpot.jackpot_name}Mobile.webp`}
                alt={jackpot.jackpot_name}
                className={clsx(styles.JackpotCard__bg_img, styles.mobile)}
            />
            
            <div className={clsx(styles.JackpotCard__content)}>
                
                <RoundedLeveling
                    className={styles.rounded_leveling}
                    value={jackpot.levels[0].percentage}
                    fever={jackpot.levels[0].fever as fever_types}
                />
                
                <BurningBox
                    main_box_class={clsx(styles.topValuesContainer)}
                    dark_box_class={clsx(styles.topValues)}
                    glowing={jackpot.levels[0].percentage > 95}
                >
                    
                    <div className={styles.val}>
                        
                        <JackpotCounterDunamic
                            id={"counter_top" + jackpot.jackpot_name}
                            textWidth={12}
                            textHeight={20}
                            style={topValsStyle}
                            className={styles.valueContainer}
                            jackpot={jackpot}
                            level={jackpot.levels[0]}
                            value={jackpot.levels[0].value && jackpot.levels[0].value}
                        />
                        
                        <span className={styles.ron}>
                        RON
                    </span>
                    
                    </div>
                    
                    
                    <div className={clsx(styles.badge, styles[jackpot.levels[0].fever])}>
                        {strings[jackpot.levels[0].fever]}
                    </div>
                    
                </BurningBox>
                
                <button
                    aria-label={"play jackpot"}
                    onClick={navigateToJPPage}
                    className={styles.JackpotCard__playBtn}
                >
                    <span>
                        JOACĂ ACUM
                    </span>
                    
                    <PlayLight/>
                </button>
            
            </div>
        </div>
    )
}

export default JackpotCard