import * as actionTypes from './actions';
import '../../interfaces/user';
import GameInterface from "../../interfaces/game";
import WinnerInterface from "../../interfaces/winner";
import {
    NAVIGATE_TO_PENDING_TICKETS,
    OPEN_ACCOUNT_SIDEBAR,
    OPEN_PAGINATION_BOTTOM,
    PROVIDER_FILTER_SLUG_PAGE,
    saveCategoryGamesCarousels,
    SET_BOTTOM_THIN,
    SET_FROM_ACC_PG,
    SET_IS_CATEG,
    SET_IS_GUEST,
    SET_IS_IN_APP,
    UPDATE_ALL_JACKPOTS,
    UPDATE_CASHOUTS,
    UPDATE_QUEST_ENDED,
    UPDATE_TICKET_ID
} from "./actions";
import Vendor from "../../interfaces/vendor";
import {RaffleStatusType, RaffleUserStatusType} from "@/AbsoluteComponents/utils/small_types";
import AllJpInterface from "@/AbsoluteComponents/interfaces/all_jp_interface";
import {jackpot_types} from "@/AbsoluteComponents/interfaces/types";
import JackpotInterface from "@/AbsoluteComponents/interfaces/jp_interface";

interface initialValues {
    allGames:Array<GameInterface>,
    updating: boolean,
    showSearchBar: boolean,
    searchValue: string,
    activeSearchProviders: Array<any>,
    searchSort:string,
    liveRTP: any,
    notifications: Array<any>,
    providers: Array<any>,
    winners: Array<WinnerInterface>,
    providersNames: Array<any>,
    vendorsNames: Array<Vendor>,
    categoryGamesCarousels: object,
    showAccountSideBar: boolean,
    showTermsSideBar:boolean,
    tableDate: Date | null,
    guestSidebar: boolean,
    isMobile: boolean,
    enableSpinWheel: string
    slugSearchProviders: Array<any>
    slugSearchFeatures: Array<any>
    slugSearchTheme: Array<any>
    slugSearchVolatility: Array<any>
    slugSearchTag: Array<any>
    playJackpot: any
    ticketId: string
    questEnded: boolean
    cashoutTickets: Array<any>
    navToPendingTickets: boolean
    isInApp: boolean
    openAccSidebar: boolean
    footerInView:boolean
    openInfoSidebar: boolean
    paginationBottom: boolean
    bottomThin: boolean
    fromAccPg: boolean
    isGuest: boolean
    isCategPage: boolean
    morePagination: boolean
    scrollToTop: boolean
    categSliderReady: boolean
    isThemesPage: boolean
    isSportPage: boolean
    raffle_status: RaffleStatusType
    user_raffle_status: RaffleUserStatusType
    raffle_winners:Array<any>
    is_samsung_browser: boolean
    all_jackpots: AllJpInterface
}

const initialState:initialValues = {
    tableDate: null,
    allGames: [],
    providersNames: [],
    vendorsNames: [],
    updating: false,
    showSearchBar: false,
    showAccountSideBar: false,
    showTermsSideBar:false,
    guestSidebar: false,
    searchValue: '',
    searchSort: 'a-z',
    providers: [],
    activeSearchProviders: [],
    // liveRTP: {
    //     hourlyHot: [],
    //     hourlyCold: [],
    //     dailyHot: [],
    //     dailyCold: [],
    // },
    liveRTP: {},
    notifications: [],
    winners: [],
    categoryGamesCarousels: {},
    isMobile: false,
    enableSpinWheel: 'loading',
    slugSearchProviders: [],
    slugSearchTheme: [],
    slugSearchVolatility: [],
    slugSearchTag: [],
    slugSearchFeatures: [],
    playJackpot: {},
    ticketId: '',
    questEnded: false,
    cashoutTickets: [],
    navToPendingTickets: false,
    isInApp: false,
    openAccSidebar: false,
    footerInView: false,
    openInfoSidebar: false,
    paginationBottom: false,
    bottomThin: false,
    fromAccPg: false,
    isGuest: false,
    isCategPage: false,
    morePagination: false,
    scrollToTop: false,
    categSliderReady: false,
    isThemesPage: false,
    isSportPage: false,
    raffle_status: {
        nickname: "",
        username: "",
        runningInstances: {
            instanceId: "",
            raffleType: "",
            minQualifyingTickets: 0,
            userTickets: 0
        },
        optedIn: true
    },
    user_raffle_status: "Accepted",
    raffle_winners:[
        {
            raffleId: "string",
            instanceId: "string",
            drawId: "string",
            username: "Cacamaca",
            prize: "hot-dog",
            winDate: "string",
            proofUrl: "string",
            isYou: true,
            pending: true,
            prizeDescription: "string",
            prizeCost: 0
        },
        
        {
            raffleId: "string",
            instanceId: "string",
            drawId: "string",
            username: "Vova",
            prize: "wrapp",
            winDate: "string",
            proofUrl: "string",
            isYou: false,
            pending: true,
            prizeDescription: "string",
            prizeCost: 0
        },
        
        {
            raffleId: "string",
            instanceId: "string",
            drawId: "string",
            username: "Ninca",
            prize: "Sasiska v teste",
            winDate: "string",
            proofUrl: "string",
            isYou: false,
            pending: true,
            prizeDescription: "string",
            prizeCost: 0
        },
        
        {
            raffleId: "string",
            instanceId: "string",
            drawId: "string",
            username: "Timafei",
            prize: "Cighire de porc",
            winDate: "string",
            proofUrl: "string",
            isYou: false,
            pending: true,
            prizeDescription: "string",
            prizeCost: 0
        },
        
        {
            raffleId: "string",
            instanceId: "string",
            drawId: "string",
            username: "Tolea",
            prize: "Popusoi hert",
            winDate: "string",
            proofUrl: "string",
            isYou: false,
            pending: true,
            prizeDescription: "string",
            prizeCost: 0
        },
        
    ],
    is_samsung_browser: false,
    all_jackpots: {
        [jackpot_types.BellLink]: undefined,
        [jackpot_types.Clover]: undefined,
        [jackpot_types.JackpotCards]: undefined,
        [jackpot_types.Egypt]: undefined,
        [jackpot_types.HighCash]: undefined,
        [jackpot_types.HotLuck]: undefined,
        [jackpot_types.PlayJackpot]: undefined,
        [jackpot_types.RegalJackpot]: undefined,
    }
};

const reducer = (state = initialState, action:any) => {
    switch (action.type) {

        case actionTypes.OPEN_ACCOUNT_SIDEBAR:
            return {...state, openAccSidebar: action.payload}
        
        case actionTypes.SET_IS_SPORT_PAGE:
            return {...state, isSportPage: action.payload}

        case actionTypes.SET_IS_THEME_PAGE:
            return {...state, isThemesPage: action.payload}

        case actionTypes.SET_CATEG_SLIDER_READY:
            return {...state, categSliderReady: action.payload}

        case actionTypes.SHOW_SCROLL_TO_TOP:
            return {...state, scrollToTop: action.payload}

        case actionTypes.SET_MORE_PAGINATION:
            return {...state, morePagination: action.payload}

        case actionTypes.SET_IS_CATEG:
            return {...state, isCategPage: action.payload}

        case actionTypes.SET_IS_GUEST:
            return {...state, isGuest: action.payload}

        case actionTypes.SET_FROM_ACC_PG:
            return {...state, fromAccPg: action.payload}

        case actionTypes.SET_BOTTOM_THIN:
            return {...state, bottomThin: action.payload}

        case actionTypes.OPEN_PAGINATION_BOTTOM:
            return {...state, paginationBottom: action.payload}

        case actionTypes.OPEN_INFO_SIDEBAR:
            return {...state, openInfoSidebar: action.payload}

        case actionTypes.SET_FOOTER_IN_VIEW:
            return {...state, footerInView: action.payload}

        case actionTypes.UPDATE_TICKET_ID:
            return {...state, ticketId: action.payload};

        case actionTypes.SET_IS_IN_APP:
            return {...state, isInApp: action.payload};

        case actionTypes.NAVIGATE_TO_PENDING_TICKETS:
            return {...state, navToPendingTickets: action.payload};

        case actionTypes.UPDATE_CASHOUTS:
            return {...state, cashoutTickets: action.payload};

        case actionTypes.UPDATE_QUEST_ENDED:
            return {...state, questEnded: action.payload};

        case actionTypes.UPDATE_BET_TICKETS:
            return {...state, betTickets: action.payload};

        case actionTypes.SET_TABLE_DATE:
            return {...state, tableDate: action.payload};

        case actionTypes.UPDATE_PLAY_JACKPOT:
            return {...state, playJackpot: {...state.playJackpot, ...action.payload}};

        case actionTypes.UPDATE_ALL_GAMES_LIST:
            return {...state, allGames: action.payload};

        case actionTypes.ALL_GAMES_LIST_IS_UPDATING:
            return {...state, updating: action.payload};

        case actionTypes.UPDATE_SEARCH_VALUE:
            return {...state, searchValue: action.payload}

        case actionTypes.UPDATE_FILTER_PROVIDERS_LIST:
            return {...state, providers: action.payload}

        case actionTypes.SHOW_SEARCH_BAR:
            return {...state, showSearchBar: action.payload}

        case  actionTypes.UPDATE_LIVE_RTP:
            return {...state, liveRTP: {...state.liveRTP, ...action.payload}};

        case  actionTypes.APPLY_SEARCH_PROVIDERS_FILTER:
            return {...state, activeSearchProviders: action.payload};

        case actionTypes.PROVIDER_FILTER_SLUG_PAGE:
            return {...state, slugSearchProviders: action.payload}

        case actionTypes.FETURES_FILTER_SLUG_PAGE:
            return {...state, slugSearchFeatures: action.payload}

        case actionTypes.VOLATILITY_FILTER_SLUG_PAGE:
            return {...state, slugSearchVolatility: action.payload}

        case actionTypes.THEME_FILTER_SLUG_PAGE:
            return {...state, slugSearchTheme: action.payload}

        case actionTypes.TAG_FILTER_SLUG_PAGE:
            return {...state, slugSearchTag: action.payload}

        case  actionTypes.APPLY_SEARCH_SORT:
            return {...state, searchSort: action.payload};

        case  actionTypes.UPDATE_WINNERS_LIST:
            return {...state, winners: action.payload};

        case actionTypes.UPDATE_SNACKBAR:
            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    {
                        message: action.payload.message,
                        type: action.payload.type,
                        active: action.payload.active,
                        link_label: action.payload.link_label && action.payload.link_label,
                        link_function: action.payload.link_function && action.payload.link_function,
                    },
                ],
            };

        case actionTypes.REMOVE_SNACKBAR:
            return  {
                ...state,
                notifications: state.notifications.filter((item: any, i:number) => action.payload !== i )
            };

        case actionTypes.SAVE_CATEGORY_GAMES_CAROUSELS:
            return  {
                ...state,
                categoryGamesCarousels: action.payload
            };

        case actionTypes.UPDATE_PROVIDERS_NAMES:
            return  {
                ...state,
                providersNames: action.payload
            };

        case actionTypes.UPDATE_VENDORS_NAMES:
            return  {
                ...state,
                vendorsNames: action.payload
            };

        case actionTypes.SHOW_ACCOUNT_SIDE_BAR:
            return {...state, showAccountSideBar: action.payload}

        case actionTypes.SHOW_TERMS_SIDE_BAR:
            return{...state, showTermsSideBar:action.payload}

        case actionTypes.SHOW_GUEST_SIDEBAR:
            return {...state, guestSidebar: action.payload}

        case actionTypes.SET_ENABLE_WHEEL:
            return {...state, enableSpinWheel: action.payload}

        case actionTypes.IS_MOBILE_PLATFORM:
            return {...state, isMobile: action.payload}
        
        case actionTypes.UPDATE_RAFFLE_STATUS:
            return {...state, raffle_status: action.payload}
        
        case actionTypes.UPDATE_USER_RAFFLE_STATUS:
            return {...state, user_raffle_status: action.payload}
        
        case actionTypes.UPDATE_RAFFLE_WINNERS:
            return {...state, raffle_winners: action.payload}
        
        case actionTypes.SET_SAMSUNG_BROWSER:
            return {...state, is_samsung_browser: action.payload}
        
        case actionTypes.UPDATE_ALL_JACKPOTS:
            return {...state, all_jackpots: {...state.all_jackpots, ...action.payload}}
        
        

        default: return state;
    }
}

export default reducer