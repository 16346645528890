import st from "@/template/styles/Ui.module.scss";
import clsx from "clsx";
import {useEffect, useRef} from "react";
import {fever_types} from "@/AbsoluteComponents/interfaces/types";
import {fever_colors} from "@/template/config/constants";

interface Props {
    className?:string
    halfClassName?:string
    value:number
    fever:fever_types
}

const RoundedLeveling:React.FC<Props> = ({className, halfClassName, value, fever}) => {
    const circle = useRef<any>(null)
    
    

    useEffect(() => {
        if(circle.current) {
            circle.current.style.transform = `rotate(${ -180 + ((180/100) * value) }deg)`
            circle.current.style.backgroundColor = fever_colors[fever]
            
        }
    }, [value])
    
    return (
        <div className={clsx(st.masked, className || "")}>
            <div
                ref={circle}
                className={clsx(st.half, halfClassName || "")}
            />
        </div>
    )
}


export default RoundedLeveling