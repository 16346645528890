import CMS from "@/AbsoluteComponents/utils/cms";
import {useDispatch, useSelector} from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import SmarticoMiniGame from "@/AbsoluteComponents/interfaces/smartico_minigames";
import st from "@/template/styles/InstantWin.module.scss"
import React, {useEffect, useRef, useState} from "react";
import useAuth from "@/AbsoluteComponents/hooks/auth";
import Button from "@/template/small_ui/button";
import strings from "utils/strings";
import {openDeposit, openLoginModal} from "@/AbsoluteComponents/store/modals/actions";
import clsx from "clsx";
import InstantWinModal from "@/template/ui/modals/instant_win_modal";
import InfoIcon from "@/template/svg/infoIcon";
import InstantWinInfo from "@/template/ui/modals/instant_win_info";
import InstantWinsIcon from "@/template/svg/instant_wins_icon";
import TicketIcon from "@/template/svg/ticket_icon";
import GobletIcon from "@/template/svg/goblet_icon";
import LazyImage from "@/AbsoluteComponents/ui/LazyImage";
import {useRouter} from "next/router";
import {getAuthToken} from "@/AbsoluteComponents/utils/cookies";
import eventEmitter from "@/AbsoluteComponents/utils/event_emmiter_instance";
import {events} from "@/AbsoluteComponents/utils/constants";
import JavaScriptChannels from "@/AbsoluteComponents/utils/js_channels";
import Page404 from "@/template/ui/page404";


interface Props {
    json: any,
    api_id: string
}

const SmarticoMinigamePage:React.FC<Props> = ({json, api_id}) => {
    
    const [loading, set_loading] = useState<boolean>(false)
    const [prize_id, set_prize_id] = useState<number | null>(null)
    const effect_ran = useRef(false)
    const interval = useRef<any>(null)
    const [open_info, set_open_info ] = useState(false)
    
    const all_mini_games = useSelector((state:any) => state.account.smarticoMiniGames)
    const data = CMS.getAllFields(json)
    const auth = useAuth()
    const dispatch = useDispatch();
    const router = useRouter()
    
    const {
        game_id,
        title,
        description,
        end_game_page_title,
        end_game_page_description,
        end_game_img,
        slug,
        terms_url,
        img,
        canceled
    } = data
    
    
    const get_current_mini_game = () => {
        
        let res:SmarticoMiniGame | undefined | {} = undefined
        
        if(Array.isArray(all_mini_games) && all_mini_games.length) {
            const obj = all_mini_games.find((x:SmarticoMiniGame) => x.id === Number(game_id))
            
            if(typeof obj !== "undefined") {
                res = obj
                
            } else res = {}
        }
        
        return res
    }
    
    const mini_game:any = get_current_mini_game()
    const game = mini_game as SmarticoMiniGame
    const prizes = game?.prizes?.filter((x:any) => x.name !== "NECASTIGATOR") || []
    const total_possible_wins = prizes.reduce((sum, transaction) => sum + (transaction.pool_initial || 0), 0) || 0
    const actual_wins = prizes.reduce((sum, transaction) => sum + (transaction.wins_count || 0), 0) || 0
    const cost_points = game?.buyin_cost_points || 0
    const is_end = actual_wins === total_possible_wins && (actual_wins !== 0 && total_possible_wins !== 0)
    // const is_end = true
    
    const open_handler = (x:boolean) => {
        set_open_info(x)
    }
    
    const go_home = () => {
        router.push("/")
    }
    
    const grid = () => {
        let initial_grid = [
            {
                element: (
                    <div className={clsx(st.grid_item, st.headings)}>
                        
                        <div className={st.headings}>
                            
                            <h1>{title}</h1>
                            
                            <span>{description}</span>
                        
                        </div>
                        
                        <span className={st.cost}>
                            Orice depunere de {cost_points * 10} lei = <span className={st.white}>
                            
                                1 șansă
                            
                                <button
                                    onClick={() => {
                                        set_open_info(true)
                                    }}
                                >
                                    
                                    <InfoIcon color={"#fff"} width={16} height={16}/>
                                    
                                </button>
                            
                            </span>
                        </span>
                    </div>
                )
            },
            
            {
                element: (
                    <div className={clsx(st.grid_item, st.cta_section)}>
                        {(Array.isArray(img) && img.length) ? (
                            
                            <img
                                src={CMS.getPublicFilePath(img[0]['name'], slug.replace("/", ""), img[0]['path'])}
                                alt="prize_img"
                                className={st.img}
                            />
                            
                        ) : null}
                        
                        <Button
                            className={st.run_btn}
                            text={game?.spin_count ? "Deschide cadoul" : "Câștigă instant"}
                            loading={loading}
                            disabled={typeof mini_game === "undefined"}
                            onClick={() => {
                                if (game?.spin_count) {
                                    set_loading(true);
                                    
                                    (window._smartico).api.playMiniGame(Number(game_id)).then((result: any) => {
                                        
                                        set_prize_id(result.prize_id);
                                        
                                        auth.updSmarticoMiniGames()
                                        
                                    }).finally(() => {
                                        set_loading(false);
                                        
                                    });
                                    
                                } else {
                                    dispatch(openDeposit(true))
                                    JavaScriptChannels.appOpenDepositScreen()
                                }
                                
                            }}
                        />
                    </div>
                )
            },
            
            {
                element: (
                    <div className={clsx(st.grid_item, st.numbers)}>
                    
                        <div className={st.nr}>
                            
                            <span className={st.label}>
                                Total Premii:
                            </span>
                            
                            <div>
                                <InstantWinsIcon/>
                                
                                <span
                                    className={st.white}
                                >
                                    {total_possible_wins || 0}
                                </span>
                            </div>
                            
                        </div>
                        
                        <div className={st.nr}>
                            
                            <span className={st.label}>
                                Câștigate:
                            </span>
                            
                            <div>
                                <GobletIcon/>
                            
                                <span
                                    className={st.white}
                                >
                                    {actual_wins || 0}
                                </span>
                            </div>
                            
                        </div>
                        
                        <div className={clsx(st.chanse, game?.spin_count > 0 && st.primary)}>
                            <TicketIcon/> Ai {game?.spin_count || 0} {game?.spin_count === 1 ? "șansă" : "șanse"}
                        </div>
                    
                    </div>
                )
            }
        ]
        
        if(Array.isArray(prizes) && prizes.length) {
            
            prizes.sort((a, b) => a.pool_initial - b.pool_initial).map((pr: any, i: number) => {
                
                const {icon, name, pool_initial} = pr
                
                initial_grid.push({
                    
                    element: (i === 0 || i === 1) ? (
                        
                        <div
                            key={`Mini_game_prize_${i}`}
                            className={clsx(st.prize, st.grid_item, st[`prize_item_${i}`])}
                        >
                            <div className={st.inf}>
                                
                                <span className={st.pr_name}>
                                    {name || ""}
                                </span>
                                
                                <span
                                    className={st.pr_count}
                                >
                                    {pool_initial || 0}X
                                </span>
                            
                            </div>
                            
                            <div className={st.img_cont}>
                                <img src={icon} alt="prize"/>
                            </div>
                            
                        </div>
                    ) : (
                        <div
                            key={`Mini_game_prize_${i}`}
                            className={clsx(st.prize, st.grid_item, st[`prize_item_${i}`])}
                        >
                            
                            <span
                                className={st.pr_count}
                            >
                                {name || ""}
                            </span>
                            
                            <div className={st.img_cont}>
                                <img src={icon} alt="prize"/>
                            </div>
                            
                            <span className={st.pr_name}>
                                {pool_initial || 0}X
                            </span>
                            
                        </div>
                    )
                    
                })
                
            })
            
        }
        
        return initial_grid
    }
    
    useEffect(() => {
        if (!canceled) {
            if (auth.isLoggedIn()) {
                
                if (!effect_ran.current) {
                    interval.current = setInterval(() => {
                        auth.updSmarticoMiniGames()
                    }, 5000)
                    
                }
                
                effect_ran.current = true
                
            }
            
            return () => {
                clearInterval(interval.current)
            }
        }
    }, [auth.isLoggedIn()]);
    
    useEffect(() => {
        if (!canceled) {
            if (typeof getAuthToken() !== "string" || (typeof getAuthToken() === "string" && !getAuthToken().length)) {
                dispatch(openLoginModal(true))
            }
            
            eventEmitter.on(events.close_login_modal, go_home)
            
            return () => {
                eventEmitter.off(events.close_login_modal, go_home)
            }
        }
    }, []);
    
    if (canceled) return <Page404 />
    
    return (
        <>
            <div className={'page-container'}>
                
                <div className={st.centered_container}>
                    
                    {auth.isLoggedIn() && (() => {
                        if (typeof mini_game === "undefined") return (
                            <div className={st.not_logged_in}>
                                <CircularProgress/>
                            </div>
                        )
                        
                        if (typeof mini_game === "object" && !Object.keys(mini_game).length) return (
                            <span className={st.cost}>
                                Jocul nu este disponibil
                            </span>
                        )
                        
                        if (typeof mini_game === "object" && Object.keys(mini_game).length && !is_end) {
                            
                            return (
                                <div className={st.grid}>
                                    
                                    {(Array.isArray(prizes) && prizes.length) ? grid().map((pr: any, i: number) => {
                                        
                                        return (
                                            <React.Fragment key={`grid_general_item_${i}`}>
                                                {pr.element}
                                            </React.Fragment>
                                        )
                                        
                                    }) : null}
                                    
                                </div>
                            )
                        }
                        
                        if(is_end){
                            return (
                                <>
                                    <div className={clsx(st.headings, st.end)}>
                                        
                                        <h2>{end_game_page_title}</h2>
                                        
                                        <span>{end_game_page_description}</span>
                                    
                                    </div>
                                    
                                    <div className={st.end_game}>
                                        
                                        {(Array.isArray(img) && img.length) ? (
                                            <LazyImage
                                                src={CMS.getPublicFilePath(end_game_img[0]['name'], slug.replace("/", ""), end_game_img[0]['path'])}
                                                alt={"no_win"}
                                                className={st.img}
                                            />
                                        ): null}
                                        
                                        <Button
                                            text={"Vezi alte bonusuri disponibile"}
                                            onClick={() => {
                                                router.push("/account/transactions/campaigns/available")
                                            }}
                                        />
                                    </div>
                                
                                </>
                            )
                        }})()
                    }
                    
                    {(!auth.isLoggedIn() && !auth.isUpdating()) && (
                        <div className={st.not_logged_in}>
                            
                            <strong>
                                {strings["login_to_continue"]}
                            </strong>
                            
                            <Button
                                text={strings["conectare"]}
                                onClick={() => {
                                    dispatch(openLoginModal(true))
                                }}
                            />
                        
                        </div>
                    )}
                    
                    {(!auth.isLoggedIn() && auth.isUpdating()) && (
                        <div className={st.not_logged_in}>
                            
                            <div>
                                <CircularProgress/>
                            </div>
                        
                        </div>
                    )}
                
                </div>
                
                {!router.pathname.includes('/webview') ? (
                    <div className={st.footer}>
                        
                        <div className={st.divider}/>
                        
                        <div className={st.content}>
                            <InfoIcon color={"#fff"} width={16} height={16}/>
                            
                            <span>Pentru a vedea Termeni și Condiții apasă <a href={terms_url} rel="noopener noreferrer"
                                                                              target="_blank">
                            aici
                        </a>
                        </span>
                        
                        </div>
                    </div>
                ) : null}
            
            </div>
            
            <InstantWinModal
                cms_data={data}
                prize_id={prize_id}
                set_prize_handler={(x) => set_prize_id(x)}
                all_prizes={game?.prizes}
                spin_count={game?.spin_count || 0}
                game_id={game_id || 0}
            />
            
            <InstantWinInfo
                open={open_info}
                open_handler={open_handler}
                data={data}
            />
        </>
    )
}

export default SmarticoMinigamePage